// This file should contain all styled components for the entire site. Styles applicable
// to the overall app are listed first in the top most layer of the style object. Component-
// specific styles are found in nested objects named after their components

// All component specific styles have comments designatin which component the styles belong to
// with an '@' before it for easy searching. For example, styles pertaining to the Header component
// can easily be found by search '@Header'.

import {
  Button,
  IconButton,
  Grid,
  styled as muiStyled,
  Theme,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import checkIcon from "./assets/images/check.svg";
import blackBG from "./assets/images/black-blur-bg.png";
import { color } from "@mui/system";

declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}
const MAX_SCREEN_WIDTH = 1300;
// Color Scheme definitions
export const colorPalette = {
  // Primary are the BLUES
  primary: {
    main: "#2F50FF",
    dark: "#241b6c",
    light: "#DDE6FF",
  },
  red: {
    main: "#C1503A",
    dark: "#A2422F",
    light: "#FFE2E4",
  },
  green: {
    main: "#105A37",
    dark: "#0D4230",
    light: "#C6F2E5",
  },
  yellow: {
    main: "#E6A128",
    dark: "#B76d10",
    light: "#F9B742",
  },
  neutral: {
    main: "#404040",
    dark: "#1D1D20",
    medium: "#E4E3E9",
    light: "#F9F9F9",
    white: "#FFFFFF",
    black: "#000000",
  },
};

type FlexBoxCustomization = {
  $height?: string;
  $width?: string;
  $justifyContent?: string;
  $alignItems?: string;
  $margin?: string;
  $padding?: string;
  $flexDirection?: string;
  $backgroundColor?: string;
  $color?: string;
  $textColor?: string;
  $flexWrap?: boolean;
  $gap?: string;
  $direction?: string;
  $overrideCenter?: boolean;
  $overflow?: string;
};

type SectionContainerTypes = {
  $height?: string;
  $minHeight?: string;
  $maxWidth?: string;
  $width?: string;
  $margin?: string;
  $padding?: string;
  $backgroundColor?: string;
  $fullWidth?: boolean;
  $background?: string;
};

const LayoutStyles = {
  Container: styled.div<{ $slideOpen: boolean }>`
    height: 100%;
    width: 100vw;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    color: ${colorPalette.neutral.black};
    transform: ${(props: any) =>
      props.$slideOpen ? "translate(-100%)" : "unset"};
    transition: transform 1s;
    background-color: #ffffff;
  `,
  PageTitle: styled(Typography).attrs({ variant: "h1" })`
    && {
      max-width: 1027px;
      text-align: center;
      margin-bottom: 20px;
      justify-content: center;
      font-weight: 500;
      color: ${colorPalette.neutral.black};
    }
  `,
  Body: styled.div`
    font-size: 18px;
    color: ${colorPalette.neutral.main};
    text-align: center;
    line-height: 26px;
    max-width: 884px;
    font-weight: 400;
    letter-spacing: -0.31px;
    font-family: "Gordita";
    margin-bottom: 45px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  `,
  HeroRoladex: styled.div`
    margin: 0;
    font-size: 56px;
    font-weight: 500;
    color: #2f50ff;
    padding: 18px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    user-select: none;
    @media (max-width: 1024px) {
      font-size: 46px;
    }
    @media (max-width: 768px) {
      font-size: 30px;
      padding: 9px;
    }
  `,
  Button: styled.button<{
    $background?: string;
    $fontColor?: string;
    $width?: string;
    $margin?: string;
    $padding?: string;
  }>`
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props: any) =>
      props.$background ? props.$background : colorPalette.neutral.white};
    color: ${(props: any) =>
      props.$fontColor ? props.$fontColor : colorPalette.neutral.black};
    padding: ${(props: any) => (props.$padding ? props.$padding : "11px 25px")};
    margin: ${(props: any) => (props.$margin ? props.$margin : "0")};
    border-radius: 23.5px;
    border: ${(props: any) =>
      props.$fontColor === "#FFFFFF"
        ? `2px solid ${props.$fontColor}`
        : "none"};
    width: ${(props: any) => (props.$width ? props.$width : "unset")};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 22px;
    cursor: pointer;
    font-family: pragmatica-extended;
    @media (max-width: 1366px) {
      &.header-btn {
        font-size: 14px;
        padding: 8px 22px;
      }
    }
    @media (max-width: 1024px) {
      &.header-btn {
        font-size: 13px;
      }
    }
    @media (max-width: 900px) {
      &.header-btn {
        font-size: 12px;
        padding: 8px 18px;
      }
    }
  `,
  GridContainer: muiStyled(Grid)`
      width: 100%;
  `,
  Pill: styled.span<{ $dark?: boolean }>`
    font-size: 12px;
    font-weight: 500;
    font-family: Gordita;
    min-width: 225px;
    letter-spacing: 1px;
    line-height: 20px;
    height: 40px;
    padding: 0 20px 0 12px;
    color: ${(props: any) =>
      props.$dark ? colorPalette.neutral.medium : colorPalette.neutral.black};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props: any) =>
      props.$dark ? "none" : `#1px solid ${colorPalette.neutral.medium}`};
    border-radius: 20px;
    background-color: ${(props: any) => (props.$dark ? "#48484E" : "#E0E8FF")};
    @media (max-width: 768px) {
      height: 36px;
      font-size: 10px;
      padding: 8px 14px;
      min-width: unset;
      width: auto;
      max-width: unset;
    }
  `,
  // General use flexbox container that can be customized using props
  FlexboxRow: styled.div<FlexBoxCustomization>`
    position: relative;
    display: flex;
    overflow: ${(props: FlexBoxCustomization) =>
      props.$overflow ? props.$overflow : "unset"};
    width: ${(props: FlexBoxCustomization) =>
      props.$width ? props.$width : "100%"};
    height: ${(props: FlexBoxCustomization) =>
      props.$height ? props.$height : "auto"};
    justify-content: ${(props: FlexBoxCustomization) =>
      props.$justifyContent ? props.$justifyContent : "space-between"};
    align-items: ${(props: FlexBoxCustomization) =>
      props.$alignItems ? props.$alignItems : "center"};
    flex-direction: ${(props: FlexBoxCustomization) =>
      props.$flexDirection ? props.$flexDirection + " !important" : "row"};
    flex-wrap: ${(props: FlexBoxCustomization) =>
      props.$flexWrap ? "wrap" : "nowrap"};
    gap: ${(props: FlexBoxCustomization) => (props.$gap ? props.$gap : "0px")};
    padding: ${(props: FlexBoxCustomization) =>
      props.$padding ? props.$padding : "0"};
    margin: ${(props: FlexBoxCustomization) =>
      props.$margin ? props.$margin : "0"};
    background-color: ${(props: FlexBoxCustomization) =>
      props.$backgroundColor ? props.$backgroundColor : "transparent"};
    color: ${(props: FlexBoxCustomization) =>
      props.$textColor ? props.$textColor : "inherit"};
    flex-direction: ${(props: FlexBoxCustomization) =>
      props.$direction ? props.$direction : "row"};
    @media (max-width: 1180px) {
      justify-content: ${(props: FlexBoxCustomization) =>
        !props.$overrideCenter
          ? "center"
          : props.$justifyContent
          ? props.$justifyContent
          : "space-between"};
    }
  `,
  BackgroundImage: styled.img`
    position: absolute;
  `,
  PageContainer: styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SectionContainer: styled.section<SectionContainerTypes>`
    max-width: ${(props) =>
      props.$maxWidth ? props.$maxWidth : `${MAX_SCREEN_WIDTH}px`};
    position: relative;
    z-index: 2;
    width: ${(props) => (props.$width ? props.$width : "100%")};
    height: ${(props) => (props.$height ? props.$height : "fit-content")};
    min-height: ${(props) => (props.$minHeight ? props.$minHeight : "0px")};
    padding: ${(props) =>
      props.$fullWidth ? "0" : props.$padding ? props.$padding : "0 35px"};
    margin: ${(props) => (props.$margin ? props.$margin : "0")};
    &:before {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      width: 300vw;
      left: -100vw;
      z-index: -1;
      background: ${(props: FlexBoxCustomization) =>
        props.$backgroundColor ?? "transparent"};
    }
  `,
  CenteredContainer: styled.div<{ $margin?: string; $padding?: string }>`
    text-align: center;
    margin: ${(props: any) => (props.$margin ? props.$margin : "0")};
    padding: ${(props: any) => (props.$padding ? props.$padding : "0")};
  `,
  VideoContainer: styled(motion.div)`
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
  `,
  AnimationContainer: styled.div`
    position: relative;
    height: 100%;
    max-width: 100%;
    z-index: 1;
  `,
  LearnMore: styled.span`
    font-size: 18px;
    cursor: pointer;
    color: ${colorPalette.neutral.black};
    line-height: 22px;
  `,
  // @Logo component
  LogoHeader: styled.div`
    font-size: 13px;
    line-height: 18px;
    font-family: Gordita;
    font-weight: 600;
    color: ${colorPalette.neutral.main};
    letter-spacing: 1.5px;
    text-align: center;
    padding-top: 88px;
  `,
  Logo: styled.img<{ $inColor?: boolean }>`
    height: 100px;
    @media (max-width: 1024px) {
      max-height: 130px;
    }
    @media (max-width: 768px) {
      max-height: 170px;
    }
    @media (max-width: 490px) {
      height: 85px;
    }
  `,
  // @Header Component styles
  Header: {
    Container: styled.div`
      height: 88px;
      background-color: #ffffff;
      width: 100vw;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      padding-top: 5px;
      z-index: 600;
      @media (max-width: 768px) {
        height: 68px;
      }
    `,
    DrawerTopBar: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 68px;
    `,
    InnerWrapper: styled.div`
      height: 100%;
      width: 100%;
      max-width: ${MAX_SCREEN_WIDTH}px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      @media (max-width: 1366px) {
        padding: 0 25px;
      }
      @media (max-width: 1024px) {
        padding: 0 15px;
      }
      @media (max-width: 768px) {
        padding: 0 25px;
      }
    `,
    DrawerWrapper: styled.div`
      height: 100%;
      width: 100%;
      display: flex;
      /* flex-direction */
    `,
    Logo: styled.img`
      height: 40px;
      width: auto;
      @media (max-width: 1024px) {
        height: 36px;
      }
    `,
    NavButtonGroup: styled.div`
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
    `,
    SignInButton: styled.a`
      border: none;
      text-decoration: none;
      color: ${colorPalette.neutral.black};
      background-color: transparent;
      margin-right: 15px;
      font-family: "Gordita";
      font-size: 15px;
    `,
    NavButton: styled.div<{ $selected?: boolean }>`
      font-family: "Gordita";
      width: 80px;
      color: ${(props) =>
        props.$selected
          ? colorPalette.primary.main
          : colorPalette.neutral.black};
      font-size: 15px;
      font-weight: ${(props) => (props.$selected ? "600" : "400")};
      letter-spacing: 0.1px;
      height: 88px;
      line-height: 21px;
      margin: 0 19px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media (max-width: 1366px) {
        margin: 0 16px;
      }
      @media (max-width: 1024px) {
        margin: 0 12px;
      }
      @media (max-width: 900px) {
        margin: 0 10px;
      }
    `,
    DropDownPage: styled.div`
      color: ${colorPalette.neutral.black};
      background-color: #ffffff;
      font-family: "Gordita";
      font-weight: 500;
      width: 325px;
      padding: 27px 32px 27px 18px;
      display: flex;
      cursor: pointer;
      align-items: center;
      border-radius: 12px;
      justify-content: space-between;
      &:hover {
        background-color: ${colorPalette.primary.light} !important;
        color: ${colorPalette.primary.main} !important;
      }
    `,
    MenuMainLink: styled.div`
      width: 100%;
      border-bottom: 1px solid rgba(228, 222, 206, 0.56);
      display: flex;
      justify-content: space-between;
      padding: 28px 24px;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px;
      border-radius: 12px;
      &:hover {
        background-color: ${colorPalette.primary.light} !important;
        color: ${colorPalette.primary.main} !important;
      }
    `,
    MenuGridItem: styled.div`
      width: 100%;
      padding: 16px 24px;
      border-radius: 12px;
      &:hover {
        background-color: ${colorPalette.primary.light} !important;
        color: ${colorPalette.primary.main} !important;
      }
    `,
    GridTitle: styled.div`
      font-family: "Gordita";
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 4px;
      white-space: normal;
    `,
    GridSubTitle: styled.div`
      font-family: "Gordita";
      color: ${colorPalette.neutral.main};
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.2px;
      max-width: 100%;
      white-space: normal;
    `,
    PageTitle: styled.div`
      font-family: "Gordita";
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 8px;
      display: flex;
    `,
    PageDesc: styled.div`
      font-family: "Gordita";
      font-size: 14px;
      font-weight: 400;
      color: ${colorPalette.neutral.main};
      letter-spacing: -0.2px;
      white-space: normal;
      @media (max-width: 1024px) {
        font-size: 14px;
      }
    `,
    DrawerIconLine: styled.div`
      height: 2px;
      width: 24px;
      border-radius: 1px;
      background-color: ${colorPalette.neutral.dark};
    `,
    DrawerTitle: styled.div`
      color: ${colorPalette.neutral.black};
      font-size: 12px;
      line-height: 27px;
      letter-spacing: 1.33px;
    `,
    DrawerOptionBox: styled.div<{ $bgColor: string }>`
      font-size: 16px;
      letter-spacing: -0.22px;
      font-weight: 600;
      background-color: #000000;
      height: 73px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #f9f9f9;
      padding: 0 24px;
      margin-bottom: 10px;
    `,
    SubDrawerTopBox: styled.div<{ $bgColor: string }>`
      background-color: ${(props) => props.$bgColor};
      width: 100%;
      padding: 22px 24px 0;
      color: #f9f9f9;
    `,
    SubDrawerTitle: styled.div`
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.25px;
      font-weight: 500;
      margin-bottom: 6px;
      color: #f9f9f9;
    `,
    SubDrawerDesc: styled.div`
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 22px;
      color: #f9f9f9;
    `,
    SubDrawerBottom: styled.div`
      padding: 36px 24px 0;
    `,
    SubLink: styled.div`
      font-family: "Gordita";
      width: 100%;
      margin-bottom: 35px;
    `,
  },
  SubMenu: {
    Container: styled.div`
      width: 100vw;
      height: 88px;
      background-color: ${colorPalette.neutral.black};
      color: ${colorPalette.neutral.white};
      display: flex;
      justify-content: center;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 601;
    `,
    InnerWrapper: styled.div`
      height: 100%;
      width: 100%;
      max-width: ${MAX_SCREEN_WIDTH}px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 75px;
    `,
    Title: styled.div`
      font-size: 18px;
      line-height: 26px
      letter-spacing: 0.13px;
      font-weight: 500;
      margin-right: 24px;
    `,
    OptionBox: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      font-size: 13px;
      font-weight: 500;
      margin-right: 24px;
      padding: 0 12px;
      cursor: pointer;
      height: 100%;
    `,
    Option: styled.div<{ $selected: boolean }>`
      display: flex;
      align-items: center;
      font-size: 17px;
      letter-spaceing: -0.24px;
      padding: 0 24px;
      height: 100%;
      cursor: pointer;
      background-color: ${(props) =>
        props.$selected ? colorPalette.primary.main : "transparent"};
    `,
  },
  // @Footer component styles
  Footer: {
    Container: styled.div<{ $isMediumScreen: boolean }>`
      width: 100vw;
      height: auto;
      display: flex;
      justify-content: center;
      color: ${colorPalette.neutral.white};
      background-color: ${colorPalette.neutral.black};
    `,
    InnerWrapper: styled.div<{ $isLanding: boolean }>`
      width: 100%;
      max-width: ${MAX_SCREEN_WIDTH}px;
      display: flex;
      padding: ${(props) =>
        props.$isLanding ? "41px 73px 25px" : "112px 73px 25px"};
      flex-direction: column;
      @media (max-width: 1366px) {
        padding: ${(props) =>
          props.$isLanding ? "41px 73px 25px" : "112px 120px 25px"};
      }
      @media (max-width: 768px) {
        padding: ${(props) =>
          props.$isLanding ? "41px 73px 25px" : "46px 24px 25px"};
      }
    `,
    Headline: styled.div`
      font-family: "Gordita";
      font-weight: 500;
      font-size: 25px;
      line-height: 20px;
      letter-spacing: -0.45px;
      color: #00000;
      margin-top: 24px;
      margin-bottom: 45px;
      @media (max-width: 887px) {
        font-size: 27px;
        line-height: 30px;
        display: flex;
        align-items: center;
        margin: 0;
      }
      @media (max-width: 768px) {
        margin-bottom: 45px;
      }
    `,
    TopRow: styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 55px;
      @media (max-width: 1024px) {
        flex-direction: column;
        margin-bottom: 0px;
        padding-bottom: 55px;
        align-items: center;
      }
    `,
    LandingLogo: styled.img`
      height: 76px;
      width: 76px;
    `,
    WrappingRow: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex: 1;
      @media (max-width: 887px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
        grid-row-gap: 40px;
      }
    `,
    SecondRow: styled.div`
      display: flex;
      justify-content: space-between;
      padding: 38px 0;
      font-family: Gordita;
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 20px;
      @media (max-width: 768px) {
        flex-direction: column;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        padding: 26px 0 30px;
      }
    `,
    AwardLogo: styled.img`
      height: 62px;
      width: auto;
      margin-bottom: 38px;
      @media (max-width: 887px) {
        height: 46px;
      }
    `,
    BottomRow: styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 13px;
      border-top: 1px solid rgba(255, 255, 255, 0.15);
      padding-top: 26px;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
        height: auto;
        margin-top: 0;
      }
    `,
    Column: styled.div`
      width: 200px;
      display: flex;
      flex-direction: column;
      margin-left: 5px;
      @media (max-width: 1024px) {
        margin-bottom: 50px;
        width: 50%;
      }
      @media (max-width: 890px) {
        padding-left: 40px;
      }
      @media (max-width: 768px) {
        max-width: 183px;
        margin-right: 10px;
        height: fit-content;
        margin-bottom: 28px;
        padding: 0;
      }
    `,
    Logo: styled.img`
      width: 150px;
      height: auto;
      @media (max-width: 890px) {
        margin-bottom: 24px;
      }
      @media (max-width: 768px) {
        width: 150px;
        margin-right: auto;
        margin-left: auto;
      }
    `,
    Title: styled.div`
      font-family: "Gordita";
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 2.29px;
      font-weight: 500;
      margin-bottom: 19px;
      @media (max-width: 887px) {
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.87px;
      }
    `,
    TextLink: styled.div`
      font-family: "Gordita";
      color: #d9dce1;
      line-height: 22px;
      margin-bottom: 28px;
      cursor: pointer;
      &:hover {
        color: #4360fe;
      }
      @media (max-width: 887px) {
        margin-bottom: 10px;
        font-size: 14px;
      }
    `,
    SocialLInk: styled.img`
      height: 20px;
      width: 20px;
      margin-right: 20px;
    `,
    SocialBox: styled.div`
      display: flex;
      @media (max-width: 768px) {
        order: 1;
        margin-bottom: 24px;
        justify-content: center;
      }
    `,
    Copyright: styled.div`
      font-size: 12px;
      line-height: 22px;
      @media (max-width: 768px) {
        order: 3;
        text-align: center;
      }
    `,
    LoveMessage: styled.div`
      font-size: 12px;
      font-family: "Gordita";
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        order: 2;
        margin-bottom: 16px;
        justify-content: center;
      }
    `,
  },
  TitleText: styled(Typography).attrs({ variant: "h2" })`
    && {
      color: ${colorPalette.neutral.black};
      padding: 0 24px;
      text-align: center;
      max-width: 905px;
      margin: 0 auto;
      @media (max-width: 768px) {
        max-width: 600px;
      }
    }
  `,
  // This is for the @TextText and @DualTxtImg componnets
  TextText: {
    Container: styled.div<{ $altColor?: boolean }>`
      max-width: 450px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      height: 100%;
      color: ${(props) =>
        props.$altColor ? "#FFFFFF !important" : colorPalette.neutral.black};
      @media (max-width: 1025px) {
        margin: auto;
        max-width: 85%;
      }
      &:first-child {
        @media (max-width: 1025px) {
          margin-bottom: 68px;
        }
      }
    `,
    Icon: styled.img`
      width: 40px;
      height: auto;
      margin-right: 30px;
    `,
    Headline: styled(Typography).attrs({ variant: "h3" })<{
      $isSmallScreen: boolean;
    }>`
      && {
        max-width: 464px;
        width: 100%;
        align-text: left;
        font-size: 45px;
        color: inherit;
        font-weight: ${(props) => (props.$isSmallScreen ? 500 : 500)};
        letter-spacing: 0.27px;
        line-height: 53px;
        margin-bottom: 31px !important;
        @media (max-width: 1024px) {
          max-width: unset;
        }
        @media (max-width: 800px) {
          font-size: 30px !important;
          margin-bottom: 24px !important;
          line-height: 39px !important;
          text-align: left !important;
        }
      }
    `,
    Body: styled.p<{ $altTextColor?: boolean }>`
      font-family: "Gordita";
      font-weight: 400;
      color: ${(props) =>
        props.$altTextColor ? "#FFFFFF" : colorPalette.neutral.main};
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 22px;
      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 21px;
        text-align: left;
      }
    `,
    IconRowImg: styled.img`
      height: 35px;
      width: auto;
    `,
    IconSubtitle: styled.div`
      font-size: 14px;
      line-height: 15px;
      letter-spacing: -0.2px;
      margin-top: 14px;
      width: 70px;
    `,
    TopImg: styled.img<{ $isSmallScreen?: boolean }>`
      margin-bottom: ${(props) => (props.$isSmallScreen ? "24px" : "64px")};
      max-width: 100%;
    `,
  },
  // @TextBox component styles
  TextBox: {
    Headline: styled.div`
      font-size: 56px;
      font-weight: bold;
      line-height: 60px;
      letter-spacing: 0.31px;
      color: ${colorPalette.neutral.black};
      max-width: 46%;
      @media (max-width: 1024px) {
        font-size: 44px;
        line-height: 48px;
      }
      @media (max-width: 768px) {
        font-size: 27px;
        line-height: 30px;
        max-width: 100%;
        text-align: center;
        margin-bottom: 24px;
      }
    `,
    Body: styled.p`
      width: 100%;
      font-weight: 400;
      font-size: 18px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
        margin-top: 15px;
      }
    `,
  },
  // @NavigationTabs
  NavigationTabs: {
    Container: styled(motion.div)`
      height: 56px;
      border-radius: 28px;
      background-color: #efefef;
      color: ${colorPalette.neutral.black};
      position: sticky;
      top: 113px;
      z-index: 999;
      display: flex;
      align-items: center;
      padding: 8px;
      column-gap: 8px;
      /* box-shadow: 0px 0px 20px 10px rgba(249, 249, 249, 0.8); */
      overflow: hidden;
      @media (max-width: 767px) {
        display: none;
        background-color: transparent;
        height: fit-content;
        width: 100%;
        padding: 0 8px;
      }
    `,
    Tab: styled(motion.div)`
      height: 40px;
      border-radius: 24px;
      padding: 10px 31.23px;
      color: #1f1f21;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.38px;
      line-height: 20px;
      cursor: pointer;
      background-color: transparent;
      position: relative;
      user-select: none;
      @media (max-width: 767px) {
        font-size: 12px;
        height: fit-content;
        text-align: center;
        word-spacing: 100vh;
        padding: 10px 0px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        @media (max-width: 767px) {
          background-color: #efefef;
          border-radius: 16px;
        }
      }
    `,
    Selected: styled(motion.div)`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: ${colorPalette.neutral.dark};

      border-radius: 24px;
      @media (max-width: 767px) {
        border-radius: 16px;
      }
    `,
  },
  // @ImageText
  ImageAndTextBox: {
    ImageArea: styled.div<{ $reverse?: boolean }>`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: ${(props) =>
        props.$reverse ? "flex-start" : "flex-end"};
      @media (max-width: 1150px) {
      }
      @media (max-width: 1025px) {
        margin-right: 0;
        aspect-ratio: auto;
      }
      @media (max-width: 768px) {
        aspect-ratio: auto;
      }
    `,
    BackgroundImg: styled.img<{ $reverse?: boolean }>`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: ${(props) => (props.$reverse ? "100px" : "unset")};
      right: ${(props) => (props.$reverse ? "unset" : "100px")};
      height: 100%;
      width: 100%;
      z-index: -1;
      @media (max-width: 1025px) {
        height: 120%;
      }
      @media (max-width: 769px) {
        max-height: 110%;
      }
      @media (max-width: 520px) {
        max-height: 335px;
      }
    `,
    TextArea: styled.div<{
      $quote?: boolean;
      $reverse?: boolean;
      $removeTxtPadding?: boolean;
    }>`
      margin: 0 auto;
      height: 100%;
      width: ${(props) => (props.$quote ? "510px" : "400px")};
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 100;
      @media (max-width: 1024px) {
        margin-bottom: 0px !important;
        width: 100%;
        max-width: ${(props) => (props.$quote ? "625px" : "unset")};
      }
      @media (max-width: 768px) {
        margin-bottom: 30px;
        max-width: ${(props) => (props.$quote ? "625px" : "unset")};
      }
    `,
    QuoteBox: styled.div<{ $reverse?: boolean; $removeTxtPadding?: boolean }>`
      margin: 0 auto;
      height: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      @media (max-width: 1024px) {
        margin-bottom: 0px !important;
        width: 100%;
        max-width: 625px;
      }
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
    `,
    FlexContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: center;
    `,
    Tag: styled.div<{ $altTextColor?: boolean }>`
      color: ${(props) =>
        props.$altTextColor ? "#FFFFFF" : colorPalette.neutral.main};
      font-family: "Gordita";
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.56px;
      line-height: 18px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 24px;
      @media (max-width: 768px) {
        font-size: 12px;
        margin-bottom: 15px;
      }
    `,
    QuoteIcon: styled.img`
      width: 63px;
      height: auto;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    `,
    Headline: styled(Typography).attrs({ variant: "h3" })<{
      $altTextColor?: boolean;
      $isSmallScreen: boolean;
    }>`
      && {
        color: ${(props) =>
          props.$altTextColor ? "#FFFFFF" : colorPalette.neutral.black};
        font-weight: ${(props) => (props.$isSmallScreen ? 500 : 500)};
        letter-spacing: 0.27px;
        margin-bottom: 31px;
        font-size: ${(props) => (props.$isSmallScreen ? "30px" : "45px")};
        line-height: ${(props) => (props.$isSmallScreen ? "39px" : "53px")};
      }
      @media (max-width: 1025px) {
        margin-bottom: 13px;
        max-width: unset;
        font-size: 36px;
      }
    `,
    Body: styled.p<{ $altTextColor?: boolean }>`
      font-family: "Gordita";
      font-weight: 400;
      color: ${(props) =>
        props.$altTextColor ? "#FFFFFF" : colorPalette.neutral.main};
      font-size: 17px;
      letter-spacing: -0.25px;
      line-height: 25px;
      @media (max-width: 1025px) {
        max-width: unset;
      }
      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 21px;
        text-align: left;
      }
    `,
    Quote: styled.div`
      letter-spacing: -0.35px;
      margin-bottom: 31px !important;
      font-size: 22px;
      line-height: 36px;
      width: 100%;
      @media (max-width: 1025px) {
        margin-bottom: 13px !important;
        max-width: 625px;
      }
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
      }
    `,
    Quotee: styled.div`
      width: 100%;
      text-align: flex-start;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
      }
    `,
    Title: styled.div`
      width: 100%;
      text-align: flex-start;
      font-size: 18px;
      font-weight: 400;
      color: ${colorPalette.neutral.main};
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
      }
    `,
    List: styled.ul<{ bulletImage: string }>`
      margin-top: 45px;
      font-family: "Gordita";
      font-size: 16px;
      letter-spacing: 0.09px;
      line-height: 22px;
      list-style: none;
      max-width: 464px;
      @media (max-width: 768px) {
        font-size: 15px;
        letter-spacing: 0.08px;
        line-height: 20px;
      }
    `,
    ListElement: styled.li<{ $first: boolean; $altColor?: boolean }>`
      display: flex;
      align-items: center;
      width: 100%;
      list-style: none;
      position: relative;
      margin: 0 0 15px 0;
      padding: 13px 0 0 0;
      color: ${(props) =>
        props.$altColor ? "#FFFFFF" : colorPalette.neutral.black};
      font-weight: 600;
      border-top: ${(props) =>
        props.$first ? "none" : `1px solid rgba(120,106,80,0.12)`};
      @media (max-width: 768px) {
        font-size: 15px;
        letter-spacing: 0.08px;
        line-height: 20px;
      }
    `,
    VideoButton: styled(Button)<{ $bgColor?: string; $fullWidth?: boolean }>`
      && {
        border: 2px solid ${colorPalette.neutral.black};
        border-radius: 26px;
        padding: 11px 30px;
        color: ${colorPalette.neutral.black};
        font-family: "Gordita";
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        background-color: ${(props) =>
          props.$bgColor ? props.$bgColor : "#ffffff"};
        width: ${(props) => (props.$fullWidth ? "100%" : "unset")};
        &:hover: {
          background-color: ${(props) =>
            props.$bgColor
              ? `${props.$bgColor} !important`
              : "#ffffff !important"};
        }
      }
    `,
    ArrowButton: styled(Button)<{
      $bgColor?: string;
      $color?: string;
      $width?: string;
      $overrideCenter?: boolean;
    }>`
      && {
        color: ${(props) => (props.$color ? props.$color : "#FFFFFF")};
        background-color: ${(props) => props.$bgColor ?? "none"};
        border-radius: 30px;
        padding: 12.5px 30px;
        font-size: 15px !important;
        font-family: "Gordita";
        font-weight: 500;
        width: ${(props) => (props.$width ? props.$width : "initial")};
        justify-content: space-between;

        &:hover {
          background-color: ${(props) => props.$bgColor ?? "none"};
        }
        @media (max-width: 887px) {
          font-size: 15px;
          margin: ${(props) => (props.$overrideCenter ? "0" : "0 auto")};
          height: 50px;
        }
      }
    `,
    Button: styled(Button)`
      color: ${colorPalette.neutral.black};
      position: relative;
      left: -8px;
      font-size: 18px !important;
      font-weight: 600 !important;
      font-family: "Pragmatica Extended";
    `,
    SlideButton: styled(motion.div)<{ $blackButton?: boolean }>`
      box-sizing: border-box;
      display: flex;
      height: 48px;
      width: 225px;
      border-radius: 24px;
      position: relative;
      background-color: ${(props) =>
        props.$blackButton ? colorPalette.neutral.black : "#ffffff"};
      color: ${(props) =>
        props.$blackButton ? "#ffffff" : colorPalette.neutral.black};
      font-size: 15px !important;
      line-height: 20px;
      font-weight: 500 !important;
      align-items: center;
      padding: 14px 25px !important;
      white-space: no-wrap;
      &::before {
        content: "";
        height: 40px;
        width: 19px;
        background-color: transparent;
        position: absolute;
        right: 0;
        z-index: 99;
      }
    `,
    ArrowIcon: styled.div<{ $blackButton?: boolean }>`
      background-color: ${(props) =>
        props.$blackButton ? colorPalette.neutral.black : "#ffffff"};
      height: 100%;
      width: 38px;
      position: absolute;
      right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
    `,
    Bullet: styled.div<{ $altColor: boolean }>`
      color: ${(props) =>
        props.$altColor ? "#ffffff" : colorPalette.neutral.black};
      font-size: 18px !important;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 18px !important;
      }
    `,
  },
  ImageCaption: {
    Container: styled.div<{ $bgColor?: string; $color?: string }>`
      height: 545px;
      min-width: 374px;
      border-radius: 20px;
      overflow: hidden;
      background-color: ${(props) => props.$bgColor};
      display: flex;
      flex-direction: column;
      color: #000000;
      @media (max-width: 1172px) {
        height: 417px;
        min-width: 303px;
      }
      @media (max-width: 1024px) {
        height: 367px;
        min-width: 288px;
      }
      @media (max-width: 864px) {
        height: 343px;
        min-width: 247px;
      }
      @media (max-width: 768px) {
        width: 100%;
        height: 545px;
        background-color: ${(props) =>
          props.$bgColor ? props.$bgColor : "unset"};
        padding: 48px 24px 24px;
      }
      @media (max-width: 491px) {
        height: 473px;
      }
    `,
    ImageArea: styled.div<{ $image: string }>`
      width: 100%;
      height: 72%;
      background-image: url(${(props) => props.$image});
      background-size: cover;
      @media (max-width: 864px) {
        height: 67%;
      }
      @media (max-width: 768px) {
        width: 90%;
        height: 73%;
        margin: 0 auto 15px;
        border-radius: 24px;
        background-position: center;
      }
      @media (max-width: 491px) {
        width: 100%;
        height: 69%;
      }
    `,
    CaptionArea: styled.div`
      width: 100%;
      height: 28%;
      padding: 31px 24px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      @media (max-width: 1172px) {
        padding: 24px 23px;
      }
      @media (max-width: 1024px) {
        padding: 16px 18px 15px;
      }
      @media (max-width: 864px) {
        height: 33%;
        padding: 24px 10px 9px;
      }
      @media (max-width: 768px) {
        width: 100%;
        font-size: 18px;
        height: 27%;
        margin: 0 auto;
      }
    `,
    Caption: styled.div`
      font-family: "Gordita";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: -0.31px;
      margin-bottom: 32px;
      line-height: 22px;
      @media (max-width: 1172px) {
        padding-bottom: 5px;
        font-size: 20px;
      }
      @media (max-width: 864px) {
        width: 100%;
      }
    `,
    Button: styled.div`
      cursor: pointer;
      width: 200px;
      height: 48px;
      margin: 0 auto;
      border-top: 2px solid rgba(255, 255, 255, 0.15);
      font-family: "Gordita";
      padding: 0 15px 0 24px;
      font-size: 15px;
      font-weight: 500;
      background-color: #000000;
      border-radius: 24px;
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      align-items: center;
      @media (max-width: 768px) {
        margin-top: 25px;
      }
    `,
  },

  TopBanner: {
    Container: styled.div`
      height: fit-content;
      z-index: 10;
      width: 100%;
      border-radius: 24px;
      padding: 0px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 768px) {
        padding: 20px;
      }
    `,
    Logo: styled.img`
      height: 64.08px;
      width: 64.08px;
    `,
    Headline: styled(Typography)`
      max-width: 996px !important;
      color: ${colorPalette.neutral.black};
      text-align: center !important;
      margin-bottom: 34px !important;
    `,
    ButtonArea: styled.div`
      display: flex;
      justify-content: space-between;
      width: 523px;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
        width: fit-content;
        height: 132px;
      }
    `,
    TryButton: styled(Button)`
      height: 56px;
      width: 250px;
      border: 2px solid ${colorPalette.neutral.black} !important;
      border-radius: 8px !important;
      color: ${colorPalette.primary.main} !important;
    `,
    ScheduleButton: styled(Button)`
      height: 56px;
      width: 250px;
      border-radius: 8px !important;
      background-color: #2f50ff !important;
      color: white !important;
    `,
    Body: styled.p`
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      text-align: center;
      max-width: 636px;
      margin-bottom: 64px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
      }
    `,
  },
  ActionBanner: {
    Container: styled.div`
      border-radius: 24px;
      background-color: ${colorPalette.primary.main};
      padding: 72px 72px 100px;
      overflow: hidden;
      position: relative;
      @media (max-width: 1172px) {
        border-radius: 0px;
        padding: 72px 30px 100px;
      }
      @media (max-width: 768px) {
        border-radius: 0;
        padding: 68px 0 54px 0;
      }
    `,
    ContentContainer: styled.div`
      min-height: 320px;
      width: 100%;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      @media (max-width: 768px) {
        height: auto;
      }
    `,
    BackgroundImage: styled.img`
      position: absolute;
      top: 224px;
      height: 625px;
      width: 1207px;
      left: calc(50% - 545.5px);
      opacity: 0.5;
      z-index: 1;
      color: ${colorPalette.neutral.black};
      object-fit: cover;
      @media (max-width: 768px) {
        top: 156px;
        height: 693px;
      }
      @media (max-width: 490px) {
        top: 226px;
        left: calc(50% - 470.5px);
        height: 543px;
        width: 1007;
      }
    `,
    Logo: styled.img`
      height: 64.08px;
      width: 64.08px;
      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    `,
    Headline: styled(Typography).attrs({ variant: "h2" })`
      && {
        width: 996px;
        color: ${colorPalette.neutral.white};
        text-align: center;
        margin: 40px 0 50px;
        max-width: 85%;
        z-index: 3;
        background-color: transparent;
        @media (max-width: 768px) {
          max-width: 85%;
          height: auto;
        }
      }
    `,
    ButtonArea: styled.div`
      display: flex;
      justify-content: space-between;
      height: 56px;
      width: 523px;
      margin-bottom: 30px;
      z-index: 3;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        height: auto;
      }
    `,
    TryButton: styled(Button)`
      height: 56px;
      width: 250px;
      border: 2px solid ${colorPalette.neutral.white} !important;
      border-radius: 8px !important;
      color: white !important;
      @media (max-width: 768px) {
        order: 2;
        font-size: 16px;
      }
    `,
    ScheduleButton: styled(Button)`
      height: 56px;
      width: 250px;
      border-radius: 8px !important;
      background-color: ${colorPalette.neutral.white} !important;
      color: ${colorPalette.neutral.dark} !important;
      @media (max-width: 768px) {
        order: 1;
        margin-bottom: 20px !important;
        font-size: 16px;
      }
    `,
  },
  CareersBanner: {
    Container: styled.div<{ $bgColor: string }>`
      border-radius: 24px;
      background-color: ${(props: any) => props.$bgColor};
      padding: 72px 72px 100px;
      overflow: hidden;
      position: relative;
      @media (max-width: 1024px) {
        border-radius: 0px;
        padding: 72px 20px 100px;
      }
      @media (max-width: 768px) {
        border-radius: 0;
        padding: 68px 0 54px 0;
        font-size: 16px;
      }
    `,
  },
  MissionBanner: {
    Container: styled.div`
      width: 100%;
      font-family: "Gordita";
      min-height: 668px;
      max-height: 900px;
      height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      color: ${colorPalette.neutral.white};
      background-color: #000000;
      @media (max-width: 768px) {
        min-height: 490px;
        padding: 0 35px;
      }
    `,
    Headline: styled(Typography).attrs({ variant: "h2" })`
      && {
        max-width: 1185px;
        font-weight: 500 !important;
        padding: 0 20px;
        text-align: center;
        z-index: 10;
        font-weight: normal;
      }
    `,
    BackgroundImg: styled.img`
      position: absolute;
      height: 200%;
      width: 200%;
      bottom: -746px;
      left: -100%;
      z-index: 1;
      transform: rotate(8deg);
      @media (max-width: 768px) {
        bottom: -512px;
      }
    `,
  },
  Testimonials: {
    TextBlock: styled.div`
      width: 100%;
      height: 100%;
      color: ${colorPalette.neutral.black};
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 690px;
      @media (max-width: 1220px) {
        transform: scale(0.9);
        width: 100%;
        height: fit-content;
        align-items: center;
      }
      @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
        align-items: center;
      }
    `,
    Headline: styled.div`
      color: ${colorPalette.neutral.black};
      font-size: 48px;
      font-weight: bold;
      letter-spacing: 0.27px;
      line-height: 54px;
      @media (max-width: 1220px) {
        margin-bottom: 40px;
        text-align: center;
      }
      @media (max-width: 768px) {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.13px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 40px;
      }
    `,
    DataArea: styled.div`
      width: 642px;
      max-width: 100%;
      height: 286px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 120px;
      row-gap: 70px;
      @media (max-width: 1220px) {
        height: fit-content;
        padding-bottom: 100px;
        column-gap: 50px;
        row-gap: 20px;
        width: fit-content;
        justify-content: space-evenly;
      }
      @media (max-width: 768px) {
        padding-bottom: 20px;
      }
    `,
    DataBlock: styled.div`
      height: 108px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1220px) {
        width: 190px;
      }
      @media (max-width: 768px) {
        height: 69px;
        width: fit-content;
      }
    `,
    DataValue: styled.h3`
      color: ${colorPalette.neutral.black};
      font-size: 66px;
      width: 212px;
      font-weight: bold;
      @media (max-width: 1220px) {
        margin: 0 auto;
        font-size: 48px;
        font-weight: bold;
        line-height: 52px;
        text-align: center;
        width: 136px;
      }
      @media (max-width: 768px) {
        font-size: 36px;
        font-weight: bold;
        line-height: 44px;
        text-align: center;
        width: 136px;
      }
    `,
    DataTitle: styled.div`
      color: ${colorPalette.primary.main};
      font-family: "Gordita";
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 24px;
      @media (max-width: 1220px) {
        text-align: center;
      }
      @media (max-width: 768px) {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.08px;
        line-height: 21px;
        text-align: center;
      }
    `,
    RightArea: styled.div`
      width: 464px;
      height: 504px;
      transform: translateY(36px);
      display: flex;
      align-self: flex-end;
      flex-direction: column;
      justify-content: space-between;
    `,
    CardStack: styled(motion.div)`
      height: 472px;
      width: 464px;
      position: relative;
      @media (max-width: 1220px) {
        align-self: center;
      }
    `,
    MobileTrack: styled(motion.div)`
      overflow-x: visible;
      display: flex;
      padding: 40px 0;
    `,
    Card: styled(motion.div)<{
      $bottom?: string;
      $left?: string;
      $zIndex?: number;
    }>`
      height: 424px;
      width: 426px;
      position: absolute;
      transform-origin: bottom right;
      padding: 0 40px;
      border-radius: 16px;
      background-color: ${colorPalette.neutral.white};
      box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
      @media (max-width: 768px) {
        position: relative;
        min-height: 415px;
        padding: 0 24px;
        min-width: 311px;
        margin: 0 12px 0 12px;
        box-shadow: 0 12px 22px 0 rgba(124, 141, 157, 0.15);
      }
    `,
    QuoteIcon: styled.img`
      margin: 48px 0 40px 0;
      height: 39px;
      width: 63px;
      @media (max-width: 768px) {
        height: 32px;
        width: 52px;
        margin: 32px 0 32px 0;
      }
    `,
    Quote: styled.div`
      font-family: "Gordita";
      font-weight: 400;
      font-size: 30px;
      letter-spacing: -0.42px;
      line-height: 41px;
      text-align: center;
      margin-bottom: 53px;
      @media (max-width: 768px) {
        font-size: 26px;
        letter-spacing: -0.36px;
        line-height: 30px;
      }
      @media (max-width: 475px) {
        font-size: 20px;
        height: auto;
        letter-spacing: -0.28px;
        line-height: 26px;
        max-width: 100%;
        margin-bottom: 27px;
      }
    `,
    QuoterSection: styled.div`
      display: flex;
      justify-content: space-between;
      width: 306px;
      height: 66px;

      @media (max-width: 768px) {
        flex-direction: column;
        height: 134px;
        max-width: 100%;
      }
    `,
    QuoterImage: styled.img`
      width: 64px;
      height: 64px;
      @media (max-width: 768px) {
        width: 52px;
        height: 52px;
      }
    `,
    QuoterInfo: styled.div`
      height: 66px;
      width: 222px;
      @media (max-width: 768px) {
      }
    `,
    Name: styled.p`
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 23px;
      text-align: center;
      @media (max-width: 768px) {
      }
    `,
    JobTitle: styled.p`
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 23px;
      font-weight: 400;
      text-align: center;
      @media (max-width: 768px) {
      }
    `,
    NavigationArea: styled.div<{ $margin?: string }>`
      width: 92px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-self: flex-end;
      margin: ${(props) => props.$margin ?? "0px"};

      float: right;
      @media (max-width: 768px) {
        float: none;
        margin: 0 auto;
      }
    `,
    NavButton: styled(IconButton)`
      background-color: ${colorPalette.primary.main} !important;
      color: white !important;
      font-weight: 500 !important;
      height: 38px;
      width: 38px;
    `,
  },
  TextSlider: {
    Headline: styled.div`
      height: 180px;
      width: 543px;
      color: ${colorPalette.neutral.black};
      font-size: 56px;
      font-weight: bold;
      letter-spacing: 0.31px;
      line-height: 60px;
      align-self: flex-start;
    `,
    SliderArea: styled.div`
      width: 387px;
      height: 235px;
      align-self: flex-end;
      padding: 0 0 0 32px;
      position: relative;
    `,
    Title: styled.h3`
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 42px;
      margin-bottom: 28px;
    `,
    Body: styled.p`
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
    `,
    ScrollBar: styled.div`
      height: 100%;
      width: 1px;
      border: 2px solid #e0e0e0;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
    `,
    ScrollProgress: styled(motion.div)`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 2px;
      border: 2px solid ${colorPalette.primary.main};
      border-radius: 10px;
    `,
  },
  //   @Statement About page component styles
  SpreadAnimation: {
    Container: styled.div`
      width: 100%;
      height: 2000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 1024px) {
        height: fit-content;
      }
    `,
    Title: styled(Typography).attrs({ variant: "h2" })`
      && {
        color: ${colorPalette.neutral.black};
        text-align: center;
        width: 100%;
        max-width: 924px;
        font-weight: bold;
      }
    `,
    Body: styled.div`
      color: #1f1f21;
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      text-align: center;
      max-width: 649px;
      margin: 0 auto;
      @media (max-width: 1024px) {
        font-size: 14px;
        letter-spacing: 0.09px;
        line-height: 21px;
        text-align: center;
      }
    `,
    InitialImageState: styled.div`
      width: 792px;
      height: 681px;
      position: relative;
    `,
    Image: styled(motion.div)<{
      $top?: string;
      $bottom?: string;
      $left?: string;
      $right?: string;
    }>`
      position: absolute;
      border-radius: 24px;
      top: ${(props) => props.$top ?? "initial"};
      bottom: ${(props) => props.$bottom ?? "initial"};
      left: ${(props) => props.$left ?? "initial"};
      right: ${(props) => props.$right ?? "initial"};
      box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);

      background-size: 103%;
      background-position: center;
      @media (max-width: 1024px) {
        width: 100%;
        text-align: center;
        position: relative;
        margin-bottom: 69px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    `,
    StatementText: styled(motion.p)`
      position: absolute;
      bottom: -49px;
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      @media (max-width: 1024px) {
        width: 100%;
        height: 55px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.09px;
        line-height: 21px;
        text-align: center;
        bottom: -55px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: 1px solid #f5f4f4;
      }
    `,
    AboutText: styled(motion.p)`
      font-family: "Gordita";
      position: absolute;
      color: ${colorPalette.neutral.black};
      font-size: 38px;
      font-weight: 500;
      letter-spacing: -0.53px;
      line-height: 44px;
      @media (max-width: 1160px) {
        width: 100%;
        font-size: 24px;
        letter-spacing: 0.13px;
        line-height: 27px;
        text-align: center;
        position: static;
      }
    `,
  },
  // @Integrations component style
  Integrations: {
    Container: styled.div`
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 618px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${colorPalette.red.main};
      color: ${colorPalette.neutral.white};
      padding: 0 40px;
      @media (max-width: 1024px) {
        padding: 0 20px;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        height: 650px;
        padding: 0;
      }
    `,
    BackgroundImg: styled.img`
      position: absolute;
      height: 200%;
      width: 200%;
      bottom: -438px;
      left: -100%;
      z-index: 1;
      transform: rotate(29deg);
      @media (max-width: 768px) {
        bottom: -840px;
      }
    `,
    TextBlock: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      z-index: 10;
      max-width: 45%;
      height: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
        padding-left: 10%;
        align-items: left;
      }
    `,
    ImageBlock: styled.div`
      flex: 1;
      max-width: 700px;
      z-index: 10;
      width: 50%;
      position: relative;
      height: 100%;
      @media (max-width: 768px) {
        height: 232px;
        width: 100%;
      }
    `,
    Pill: styled.div`
      height: 41px;
      width: 157px;
      border: 1px solid ${colorPalette.neutral.white};
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        margin-bottom: 32px;
        font-size: 10px;
      }
    `,
    Title: styled(Typography).attrs({ variant: "h3" })`
      && {
        margin-bottom: 24px;
        @media (max-width: 768px) {
          max-width: 80%;
          text-align: left;
        }
      }
    `,
    Body: styled.div`
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
      max-width: 500px;
      letter-spacing: 0.1px;
      font-family: Gordita;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
        max-width: 80%;
        text-align: left;
      }
    `,
    IntLogo: styled(motion.img)<{
      $top?: string;
      $right?: string;
      $left?: string;
      $bottom?: string;
      $size?: string;
    }>`
      position: absolute;
      top: ${(props: any) => (props.$top ? props.$top : "unset")};
      bottom: ${(props: any) => (props.$bottom ? props.$bottom : "unset")};
      left: ${(props: any) => (props.$left ? props.$left : "unset")};
      right: ${(props: any) => (props.$right ? props.$right : "unset")};
      height: ${(props: any) => props.$size};
      width: ${(props: any) => props.$size};
    `,
  },
  TextFirstHero: {
    Container: styled.div<{ $textFirst?: boolean }>`
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      flex-direction: ${(props: any) =>
        props.$textFirst ? "column" : "column-reverse"};
      padding: 45px 24px 0 24px;
    `,
    GraphicContainer: styled.div<{ $height: number; $width: number }>`
      height: ${(props) => props.$height};
      width: ${(props) => props.$width};
    `,
  },
  HeroImage: {
    Container: styled.div`
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 24px;
      flex-direction: column;
      @media (max-width: 768px) {
        padding-bottom: 40px;
      }
    `,
    HeroImg: styled.img`
      width: 85%;
      max-width: 931px;
      max-height: 100%;
      z-index: 1;
      margin-bottom: 119px;
      border-radius: 24px;
      @media (max-width: 768px) {
        margin-bottom: 0px;

        object-fit: cover;
      }
      @media (max-width: 500px) {
        height: 242px;
      }
    `,
    ColorBlock: styled.div<{ $bgColor: string }>`
      width: 100%;
      background-color: ${(props) => props.$bgColor};
      height: 50%;
      position: absolute;
      bottom: 0;
    `,
  },
  VideoBlock: {
    Headline: styled.div`
      font-size: 45px;
      font-weight: 500;
      max-width: 800px;
      text-align: center;
      margin-bottom: 32px;
      @media (max-width: 768px) {
        font-size: 32px;
        line-height: 35px;
      }
    `,
    Subtitle: styled.div`
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      color: #404040;
      margin-bottom: 65px;
      max-width: 607px;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 15px;
        margin-bottom: 36px;
        max-width: 90%;
      }
    `,
    Video: styled.iframe`
      width: 100%;
      max-width: 965px;
      margin: auto;
      border-radius: 12px;
      cursor: pointer;
      border: none;
    `,
    ColorBlock: styled.div<{ $bgColor: string }>`
      width: 200vw;
      background-color: ${(props) => props.$bgColor};
      height: 50%;
      position: absolute;
      bottom: 0;
      left: -50%;
      z-index: -1;
    `,
  },
  SlidePanel: {
    Container: styled.div<{ $background: string; $slideOpen: boolean }>`
      background-color: ${(props: any) => props.$background};
      position: absolute;
      left: ${(props: any) => (props.$slideOpen ? "0" : "100%")};
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 999999;
      overflow: hidden;
      transition: left 1s;
    `,
    Text: styled.div`
      font-family: Gordita;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.2px;
    `,
    VertSection: styled.div<{ $section?: boolean }>`
      flex: none;
      width: 550px;
      max-width: 100vw;
      min-width: 0;
      margin-left: ${(props: any) => (props.$section ? "130px" : "0")};
      @media (max-width: 768px) {
        padding: 0 8px;
        margin-left: 30px;
        max-width: 327px;
        width: auto;
        margin-left: ${(props: any) => (props.$section ? "100px" : "0")};
      }
    `,
    HorSection: styled.div<{ $section?: boolean; $height?: string }>`
      flex: none;
      margin-left: ${(props: any) => (props.$section ? "190px" : "45px")};
      height: ${(props: any) => (props.$height ? props.$height : "100%")};
      display: flex;
      align-items: center;
      min-width: 0;
    `,
    Icon: styled.img`
      height: 44px;
      width: 44px;
      margin-bottom: 45px;
    `,
    FAIcon: styled(FontAwesomeIcon)`
      height: 44px;
      width: 44px;
      margin-bottom: 45px;
      @media (max-width: 768px) {
        margin-bottom: 25px;
      }
    `,
    Title: styled.div`
      font-size: 48px;
      line-height: 54px;
      letter-spacing: 0.27px;
      font-weight: 700;
      margin-bottom: 24px;
      @media (max-width: 768px) {
        font-size: 27px;
        line-height: 30px;
      }
    `,
    TextBlock: styled.div`
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.2px;
      font-weight: 600;
      font-family: Gordita;
      margin-bottom: 24px;
      margin-left: 50px;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 25px;
        max-width: 322px;
        margin-left: 0px;
      }
    `,
    Subtitle: styled.div`
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.2px;
      font-weight: 600;
      font-family: Gordita;
      margin-bottom: 32px;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 25px;
      }
    `,
    Body: styled.div`
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      font-family: Gordita;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
      }
    `,
    Graphic: styled.img<{ $width?: string }>`
      width: ${(props: any) => (props.$width ? props.$width : "unset")};
      max-width: 763px;
      height: auto;
      @media (max-width: 768px) {
        width: 380px;
      }
    `,
    Controls: styled.div`
      padding-left: 20px;
      position: sticky;
      bottom: 40px;
      left: 0px;
      display: flex;
      @media (max-width: 768px) {
        bottom: 30px;
      }
    `,
    CloseButton: styled.div<{ $color: string }>`
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: ${(props: any) => props.$color};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 24px;
      font-weight: 400;
      max-width: 500px;
      letter-spacing: 0.1px;
      font-family: Gordita;
      margin-right: 16px;
    `,
    Scrollbar: styled(motion.div)<{ $color: string }>`
      height: 40px;
      z-index: 999;
      width: 245px;
      border-radius: 20px;
      position: relative;
      background-color: ${(props) => props.$color};
    `,
    ScrollThumb: styled(motion.div)<{ $leftPosition: string; $color: string }>`
      position: absolute;
      left: ${(props: any) => props.$leftPosition};
      top: 4.5px;
      height: 30px;
      width: 56px;
      border-radius: 16px;
      background-color: ${(props: any) => props.$color};
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
      cursor: grab;
    `,
  },
  Slideshow: {
    Container: styled.div`
      width: 100%;
      height: auto;
      padding: 0 25px;
    `,
    EnvImage: styled.iframe`
      width: 100%;
      height: 512px;
      border-radius: 12px;
      border: none;
      cursor: grab;
      @media (max-width: 1024px) {
        height: 400px;
      }
      @media (max-width: 768px) {
        height: 300px;
      }
      @media (max-width: 425px) {
        height: 223px;
      }
    `,
    EnvThumbnail: styled.img`
      height: 100%;
      width: 100%;
      cursor: pointer;
    `,
    ThumbColumn: styled.div`
      max-width: 170px;
      margin-left: 18px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `,
    Thumbnail: styled.img`
      height: 92px;
      width: 142px;
      border-radius: 15px;
      margin-bottom: 12px;
      cursor: pointer;
    `,
    DragInstructModal: styled.div`
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-color: rgba(25, 28, 36, 0.7);
      color: ${colorPalette.neutral.white};
      width: 148px;
      height: 134px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400px;
      font-family: Gordita;
      padding: 0 14px;
      z-index: 100;
    `,
    InsText: styled.div`
      width: 100%;
      text-align: center;
      margin-top: 14px;
    `,
    ThreeSixtyTag: styled.div<{ align?: "center" | "left" }>`
      font-size: 11px;
      line-height: 15px;
      padding: 10px;
      background-color: rgba(25, 28, 36, 0.65);
      border-radius: 24px;
      position: absolute;
      bottom: 12px;
      left: ${(props: any) => (props.align === "left" ? "8px" : "50%")};
      transform: ${(props: any) =>
        props.align === "left" ? "none" : "translateX(-50%)"};
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Gordita;
      z-index: 700;
      color: ${colorPalette.neutral.white};
    `,
    FullScreenIframe: styled.iframe`
      height: 100vh;
      width: 100vw;
      z-index: 700;
      position: fixed;
      top: 0;
      left: 0;
    `,
    IframeCloseBtn: styled.div`
      position: fixed;
      top: 25px;
      right: 25px;
      z-index: 701;
      background-color: rgba(25, 28, 36, 0.65);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    `,
  },
  LandingHero: {
    Title: styled.div`
      font-size: 45px;
      font-weight: 500;
      line-height: 53px;
      width: 45%;
    `,
    Body: styled.div`
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      width: 45%;
    `,
    BoxHeader: styled.div<{ $color?: string }>`
      font-size: 14px;
      letter-spacing: 1.56px;
      line-height: 18px;
      justify-self: start;
      color: ${(props) => (props.$color ? props.$color : "#000000")};
    `,
    GridBox: styled.div<{
      $background?: string;
      $color?: string;
      $align?: string;
      $backgroundImage?: string;
    }>`
      height: 100%;
      width: 100%;
      position: relative;
      border-radius: 20px;
      background: ${(props) =>
        `${props.$background ? props.$background : "#ffffff"} ${
          props.$backgroundImage ? `url(${props.$backgroundImage})` : "none"
        } no-repeat`};
      background-size: cover;
      color: ${(props) => (props.$color ? props.$color : "#000000")};
      font-family: Gordita;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: -0.5px;
      text-align: center;
      line-height: 42px;
      display: flex;
      flex-direction: column;
      align-items: ${(props) => (props.$align ? props.$align : "center")};
      justify-content: space-between;
      padding: 34px 16px 16px 34px;
      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        flex-basis: auto;
      }
    `,
  },
  LandingSpread: {
    Image: styled(motion.div)<{
      $top?: string;
      $bottom?: string;
      $left?: string;
      $right?: string;
    }>`
      position: absolute;
      border-radius: 24px;
      top: ${(props) => props.$top ?? "initial"};
      bottom: ${(props) => props.$bottom ?? "initial"};
      left: ${(props) => props.$left ?? "initial"};
      right: ${(props) => props.$right ?? "initial"};
      background-repeat: no-repeat;
      background-size: 103%;
      background-position: center;
      @media (max-width: 1024px) {
        width: 100%;
        text-align: center;
        position: relative;
        margin-bottom: 69px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    `,
  },
  Subscribe: {
    Box: styled.div`
      width: 100%;
      max-width: 627px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
    `,
    Title: styled.div`
      font-size: 45px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 56px;
      letter-spacing: -1.06px;
    `,
    Bar: styled.div`
      width: 100%;
      height: 64px;
      border-radius: 32px;
      background-color: #ffffff;
      padding: 7px 7px 7px 28px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    `,
    SubscribeButton: styled.button`
      width: 168px;
      height: 100%;
      border-radius: 25px;
      background-color: ${colorPalette.primary.main};
      border: none;
      color: #ffffff;
      font-size: 16px;
      letter-spacing: 0.22px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 13px 0 26px;
      cursor: pointer;
    `,
  },
  // @FaqSection
  FaqSection: {
    Container: styled.div`
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    Title: styled(Typography).attrs({ variant: "h3" })`
      && {
        font-size: 45px;
        font-weigth: 500;
        width: 100%;
        color: ${colorPalette.neutral.black};
        text-align: center;
        margin-bottom: 56px;
      }
    `,
    Box: styled(motion.div)`
      max-width: 986px;
      width: 100%;
      background-color: white;
      border-radius: 13px;
      overflow: hidden;
      margin-bottom: 12px;
      padding: 0 33.86px 0 48px;
      position: relative;
    `,
    Question: styled.div`
      height: 96px;
      width: 100%;
      display: flex;
      align-items: center;
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 18px;
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 28px;
      z-index: 1;
      @media (max-width: 768px) {
        font-size: 16px;
        height: 66px;
      }
    `,

    Answer: styled.div`
      max-width: 712px;
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      margin-bottom: 32px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    `,
    ExpandButton: styled(motion.img)<{ $isSmallScreen: boolean }>`
      height: 20px;
      width: 20px;
      transform: rotate(315deg);
      position: absolute;
      top: ${(props: any) => (props.$isSmallScreen ? "24.14px" : "38.14px")};
      right: 33.86px;
      cursor: pointer;
    `,
  },
  // @Enterprise Hero and Form
  Enterprise: {
    Hero: styled.div`
      position: relative;
      width: 100%;
      height: 1010px;
      background-color: ${colorPalette.neutral.black};
      color: ${colorPalette.neutral.white};
      display: flex;
      justify-content: center;
      overflow: hidden;
      padding-top: 120px;
      @media (max-width: 1366px) {
        padding: 105px 25px 0 25px;
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding-top: 64px;
      }
    `,
    TextBox: styled.div`
      display: flex;
      flex-direction: column;
      max-width: 483px;
      margin-right: 77px;
      z-index: 300;
      @media (max-width: 1024px) {
        margin: 0;
        max-width: 590px;
      }
    `,
    Title: styled(Typography).attrs({ variant: "h2" })`
      && {
        margin-bottom: 32px;
        @media (max-width: 1024px) {
          margin-bottom: 20px;
          text-align: center;
        }
        @media (max-width: 768px) {
          margin-bottom: 24px;
        }
      }
    `,
    Body: styled.div`
      font-family: Gordita;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.1px;
      @media (max-width: 1024px) {
        text-align: center;
      }
    `,
    Form: styled.form<{ $isMediumScreen: boolean; $isLargeScreen: boolean }>`
      height: ${(props: any) => (props.$isMediumScreen ? "auto" : "725px")};
      max-width: ${(props: any) =>
        props.$isLargeScreen
          ? "580px"
          : props.$isMediumScreen
          ? "unset"
          : "520px"};
      width: ${(props: any) => (props.$isMediumScreen ? "100%" : "unset")};
      background-color: ${colorPalette.neutral.white};
      border-radius: 16px;
      padding: 60px 32px;
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;
      justify-content: space-between;
      margin-bottom: ${(props: any) => (props.$isMediumScreen ? "56px" : "0")};
      z-index: 300;
    `,
    Heading: styled.div`
      font-family: Gordita;
      font-weight: 500;
      font-size: 18px;
      color: ${colorPalette.neutral.medium};
      margin: 56px 0 24px 0;
      @media (max-width: 1024px) {
        text-align: center;
        margin-top: 36px;
      }
    `,
    BackgroundImg: styled.img`
      position: absolute;
      transform: rotate(-61deg);
      height: 91%;
      width: 100%;
      right: -45%;
      bottom: 142px;
    `,
  },
  // @FeatureGrid styles
  FeatureGrid: {
    Container: styled.div`
      width: 100%;
      max-width: ${MAX_SCREEN_WIDTH}px;
      margin: auto;
      padding: 0 30px;
      margin-bottom: 80px;
      color: #f9f9f9;
    `,
    Headline: styled(Typography).attrs({ variant: "h3" })<{
      $centered?: boolean;
    }>`
      && {
        margin-bottom: 33px;
        color: #ffffff;
        font-size: 45px;
        line-height: 52px;
        margin: ${(props: any) => (props.$centered ? "auto" : "unset")};
        text-align: ${(props: any) => (props.$centered ? "center" : "unset")};
        margin-bottom: 96px;
        font-weight: 500;
        @media (max-width: 768px) {
          height: auto;
          margin-bottom: 50px;
        }
      }
    `,
    GridBox: styled.div`
      height: 100%;
      width: 100%;
      border-radius: 20px;
      background-color: #ffffff;
      color: #ffffff;
      font-family: Gordita;
      display: flex;
      background-color: #2b2f34;
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.67);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 827px) {
        width: 75%;
      }
      @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        flex-basis: auto;
      }
    `,
    Icon: styled.img`
      height: 44px;
      width: auto;
      margin: 0 auto 24px;
    `,
    Title: styled.div`
      font-size: 18px;
      width: 100%;
      text-align: center;
      line-height: 28px;
      letter-spacing: -0.2px;
      font-weight: 500;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    `,
    Body: styled.div`
      font-size: 18px;
      letter-spacing: -0.2px;
      width: 100%;
      text-align: center;
      line-height: 22px;
      font-weight: 400;
      @media (max-width: 768px) {
        font-size: 16px;
        text-align: center;
      }
    `,
  },
  // @HelpBanner styles
  HelpBanner: {
    Container: styled.div<{
      $isMediumScreen: boolean;
      $bgColor: string;
      $bgImage: string;
    }>`
      width: 100%;
      height: ${(props: any) => (props.$isMediumScreen ? "310px" : "378px")};
      background-color: ${(props: any) => props.$bgColor};
      color: ${colorPalette.neutral.white};
      display: flex;
      padding: 0 25px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url(${(props: any) => props.$bgImage});
    `,
    Title: styled(Typography).attrs({ variant: "h1" })`
      && {
        margin-bottom: 30px;
        max-width: 996px;
        text-align: center;
      }
    `,
    Body: styled.div`
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.1px;
      font-weight: 400;
      max-width: 70%;
      text-align: center;
      font-family: Gordita;
    `,
  },
  VideoModal: {
    Container: styled.div`
      position: fixed;
      background-color: rgba(35, 35, 38, 0.8);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(8px);
      padding: 0 24px;
      @media (max-width: 768px) {
        padding: 0;
      }
    `,
    VideoContainer: styled.iframe`
      max-height: 532px;
      max-width: 946px;
      height: 100%;
      width: 100%;
      border-radius: 15px;
      background-color: aliceblue;
      @media (max-width: 768px) {
        border-radius: 0px;
        max-height: 56vw;
        max-width: 100vw;
      }
    `,
    Title: styled.h3`
      color: #ffffff;
      font-family: "Gordita";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.12px;
      line-height: 28px;
      margin: 40px 0 15px 0;
      @media (max-width: 768px) {
        display: none;
      }
    `,
    Description: styled.p`
      color: #ffffff;
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      @media (max-width: 768px) {
        display: none;
      }
    `,
    TextArea: styled.div`
      max-width: 946px;
      height: fit-content;
      width: 100%;
    `,
    CloseButton: styled(IconButton)`
      height: 48px !important;
      width: 48px !important;
      position: absolute !important;
      background-color: white !important;
      border-radius: 50%;
      top: 24px;
      right: 24px;
    `,
    IframeModal: styled.iframe`
      max-height: 729px;
      max-width: 1159px;
      height: 100%;
      width: 100%;
      border-radius: 15px;
      @media (max-width: 768px) {
        border-radius: 0;
        height: 100%;
        max-height: unset;
      }
    `,
  },
  PlatformHero: {
    Container: styled.div`
      height: 564px;
      max-width: 888px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      @media (max-width: 1024px) {
        transform: scale(0.8);
      }
      @media (max-width: 880px) {
        transform: scale(0.7);
      }
      @media (max-width: 860px) {
        transform: scale(1.4);
        height: 254px;
        max-width: 369px;
        width: 100%;
      }

      @media (max-width: 550px) {
        transform: scale(1);
      }
    `,
    Image: styled(motion.img)`
      position: absolute;
      box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
    `,
    Reactions: styled(motion.div)<{ $backgroundImage: string }>`
      position: absolute;
      box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
      height: 64px;
      width: 220px;
      right: 173px;
      bottom: 39.8px;
      border-radius: 45px;
      background-image: ${(props) => props.$backgroundImage};
      background-size: cover;
      background-color: white;
    `,
    ColorPicker: styled(motion.div)`
      height: 84px;
      width: 93px;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 0 16px 33px 0 rgba(37, 48, 60, 0.42);
      position: absolute;
    `,
  },
  VirtualSalesHero: {
    Container: styled.div`
      height: 479.17px;
      width: 788.39px;
      margin: 0 auto;
      position: relative;
      @media (max-width: 1180px) {
        transform: scale(0.8);
      }
      @media (max-width: 990px) {
        transform: scale(0.7);
      }
      @media (max-width: 860px) {
        transform: scale(1.4);
        height: 239px;
        max-width: 300px;
        width: 100%;
      }

      @media (max-width: 550px) {
        transform: scale(1);
      }
    `,
    Image: styled(motion.img)`
      position: absolute;
      box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
    `,
    Reactions: styled(motion.div)<{ $backgroundImage: string }>`
      position: absolute;
      box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
      height: 64px;
      width: 220px;
      top: 65.02px;
      right: 40.69px;
      border-radius: 45px;
      background-image: ${(props) => props.$backgroundImage};
      background-size: cover;
      background-color: white;
      z-index: 2;
      @media (max-width: 860px) {
        position: absolute;
        height: 30px;
        width: 103.13px;
        top: 24.34px;
        right: 35.54px;
        border-radius: 32px;
        background-color: #ffffff;
        box-shadow: 0 7px 32px 0 rgba(124, 141, 157, 0.15);
      }
    `,
  },
  EngagementHero: {
    Container: styled.div`
      max-height: 564.94px;
      max-width: 770px;
      min-height: 100%;
      margin: 80px auto 20px auto;

      @media (max-width: 990px) {
        padding: 0 20px;
      }

      @media (max-width: 550px) {
        transform: scale(1);
      }
    `,
  },
  // @Awards component
  Awards: {
    Headline: styled(Typography).attrs({ variant: "h3" })`
      && {
        color: ${colorPalette.neutral.black};
        text-align: center;
        max-width: 760px;
        font-weight: 500;
        font-size: 45px;
        margin: 0 auto 49px auto;
        @media (max-width: 768px) {
          max-width: 85%;
          height: auto;
          font-size: 30px;
        }
      }
    `,
    AwardsArea: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    `,
    AwardsBox: styled.div<{ $color?: string | undefined }>`
      height: 372px;
      width: 270px;
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      border-radius: 20px;
      flex-direction: column;
    `,
    AwardTitle: styled.p`
      color: ${colorPalette.neutral.black};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.09px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 24px;
      font-family: "Gordita";
      padding: 0 30px;
    `,
  },
  GraphicsBlock: styled.div`
    margin: 0 auto;
  `,
  PillContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 900px;
    gap: 8px;
    margin: 0 auto;
  `,
  Plans: {
    Container: styled.div`
      display: flex;
      width: 100%;
      justify-content: center;
    `,
    //1279
    FreeTier: styled.div`
      height: 748px;
      width: 24%;
      margin-right: 16px;
      border-radius: 16px;
      background-color: #ffffff;
      box-shadow: 0 16px 40px 0 rgba(124, 141, 157, 0.15);
      overflow: hidden;
    `,
    Tiers: styled.div`
      height: 748px;
      width: 74.4%; //952px;
      border-radius: 16px;
      background-color: #ffffff;
      box-shadow: 0 16px 40px 0 rgba(124, 141, 157, 0.15);
      overflow: hidden;
    `,
    TiersContent: styled.div`
      width: 100%;
      height: 100%;
      padding: 53px 32px 56px 32px;
      display: flex;
      justify-content: space-between;
    `,
    ImageArea: styled(motion.div)`
      width: 57.6%;
      height: 85.83%;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5%;
    `,
    TextArea: styled.div`
      width: 33.4%;
      height: 100%;
    `,
    Headline: styled(motion.div)`
      font-size: 32px;
      font-weight: bold;
      letter-spacing: 0.18px;
      line-height: 36px;
      margin-bottom: 12%;
      color: ${colorPalette.neutral.black};
      @media (max-width: 1024px) {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.17px;
        line-height: 34px;
      }
    `,
    Body: styled.div`
      color: ${colorPalette.neutral.black};
      font-family: "Gordita";
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      margin-bottom: 41px;
    `,
    SignUpButton: styled(Button)`
      height: 56px;
      width: 100%;
      border-radius: 8px !important;
      background-color: #2f50ff !important;
      color: ${colorPalette.neutral.white} !important;
      margin-bottom: 12% !important;
    `,
    SalesButton: styled(Button)`
      height: 56px;
      width: 89%;
      border: 2px solid #2f50ff !important;
      border-radius: 8px !important;
      color: #2f50ff !important;
      margin-bottom: 12% !important;
      @media (max-width: 1024px) {
        width: 100%;
        font-size: 16px !important;
        font-weight: 500 !important;
      }
    `,
    ListTitle: styled.h6`
      color: #9794ab;
      font-family: "Gordita";
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: 14px;
      @media (max-width: 1024px) {
      }
    `,
    List: styled(motion.ul)`
      margin-top: 18px;
      font-family: "Gordita";
      font-size: 16px;
      letter-spacing: 0.09px;
      line-height: 22px;
      list-style: none;
      max-width: 464px;
      padding-left: 0;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        font-size: 14px;
        letter-spacing: 0.08px;
        line-height: 20px;
      }
    `,
    ListElement: styled.li`
      list-style: none;
      padding-left: 27px;
      position: relative;
      margin-bottom: 20px;
      margin-left: 0;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        font-size: 14px;
        letter-spacing: 0.08px;
        line-height: 20px;
      }
      &:before {
        content: "";
        background-image: url(${checkIcon});
        min-width: 16px;
        min-height: 16px;
        margin-right: 5px;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 3px;
      }
    `,

    TabsArea: styled.div`
      height: 14.17%;
      width: 100%;
      display: flex;
    `,
    Tab: styled(motion.div)`
      background-color: ${colorPalette.neutral.light};
      border-bottom: 1px solid #e4e4e4;
      width: 33.33%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.13px;
      line-height: 28px;
      text-align: center;
      padding: 0 10%;
      color: ${colorPalette.neutral.black};
    `,
    MobileContainer: styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 50px;
    `,
    MobileTier: styled.div`
      height: 648px;
      width: 327px;
      border-radius: 16px;
      background-color: #ffffff;
      box-shadow: 0 16px 40px 0 rgba(124, 141, 157, 0.15);
      overflow: hidden;
    `,
    MobileTitle: styled.div<{ $backgroundColor: string }>`
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.$backgroundColor};
      color: ${colorPalette.neutral.white};
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 28px;
      text-align: center;
    `,
  },
  Careers: {
    Container: styled.div`
      display: flex;
      flex-wrap: wrap;
      position: relative;
      gap: 24px;
      @media (max-width: 1169px) {
        justify-content: center;
        align-items: center;
      }
    `,
    Card: styled(motion.div)`
      height: 368px;
      width: 374px;

      border-radius: 16px;
      background-color: ${colorPalette.neutral.white};
      box-shadow: 0 4px 20px 0 rgba(112, 144, 176, 0.25);
      color: ${colorPalette.neutral.black};
      padding: 48px 33px 40px 33px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 0;
      @media (max-width: 768px) {
        height: 214px;
        width: 327px;
        max-width: 374px;
        box-shadow: 0 4px 20px 0 rgba(112, 144, 176, 0.25);
        padding: 32px;
      }
    `,
    CardTitle: styled(Typography).attrs({ variant: "h3" })`
      && {
        margin-bottom: 23px;
        text-align: left;
      }
    `,

    BgImage: styled(motion.img)`
      height: 554.93px;
      width: 635.58px;
      transform: rotate(-45deg);
      position: absolute;
      top: 150px;
      left: 75px;
      z-index: -1;
      user-select: none;
    `,

    RoleTitle: styled(Typography).attrs({ variant: "h1" })`
      && {
        margin: 0 auto 40px;
        text-align: center;

        @media (max-width: 768px) {
          max-width: 327px;
        }
      }
    `,
    DescTitle: styled(Typography).attrs({ variant: "h4" })`
      && {
        font-family: inherit;
        text-align: left;
        margin-bottom: 24px;
      }
    `,
    DescBody: styled(Typography).attrs({ variant: "body1" })`
      && {
        margin-bottom: 40px;
      }
    `,
    ListTitle: styled.h6`
      color: #9794ab;
      font-family: "Gordita";
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: 14px;
      margin-bottom: 24px;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        size: 16px;
      }
    `,
    List: styled.ul`
      margin-top: 18px;
      margin-bottom: 80px;
      font-family: "Gordita";
      font-size: 16px;
      letter-spacing: 0.09px;
      line-height: 22px;
      list-style: none;
      padding-left: 0;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        font-size: 14px;
        letter-spacing: 0.08px;
        line-height: 20px;
        margin-bottom: 53px;
      }
    `,
    ListElement: styled.li`
      list-style: none;
      padding-left: 27px;
      position: relative;
      margin-bottom: 20px;
      margin-left: 0;
      @media (max-width: 1024px) {
      }
      @media (max-width: 768px) {
        font-size: 14px;
        letter-spacing: 0.08px;
        line-height: 20px;
      }
      &:before {
        content: "";
        background-image: url(${checkIcon});
        min-width: 16px;
        min-height: 16px;
        margin-right: 5px;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 3px;
      }
    `,
  },
  // @About
  About: {
    AvatarGrid: styled.div`
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1200px;
      height: 598px;
      padding: 0 25px;
      gap: 24px;
      margin: 80px auto 0 auto;
      @media (max-width: 1220px) {
        height: 550px;
      }
      @media (max-width: 767px) {
        height: auto;
        margin-top: 40px;
      }
    `,
    GridColumn: styled.div<{ $maxWidth?: string; $flex?: string }>`
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      height: 100%;
      max-width: ${(props: any) =>
        props.$maxWidth ? props.$maxWidth : "unset"};
      flex: ${(props: any) => (props.$flex ? props.$flex : "0")};
      gap: 24px;
      @media (max-width: 1220px) {
        &.mid-column {
          flex: 1;
        }
      }
    `,
    GridRow: styled.div`
      display: flex;
      gap: 24px;
      width: 100%;
    `,
    ThinGridColumn: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 25%;
      max-width: 270px;
      gap: 24px;
    `,
    GridItem: styled.div<{
      $height: string;
      $width?: string;
      $bgColor: string;
      $maxWidth?: string;
    }>`
      height: ${(props: any) => props.$height};
      width: ${(props: any) => (props.$width ? props.$width : "auto")};
      max-width: ${(props: any) =>
        props.$maxWidth ? props.$maxWidth : "unset"};
      background-color: ${(props: any) => props.$bgColor};
      overflow: hidden;
      position: relative;
      border-radius: 12px;
      @media (max-width: 1220px) {
        &.large-horizontal {
          width: 100%;
        }
        &.top-mid {
          height: 200px;
        }
      }
    `,
    AvatarImg: styled.img<{ $width: string }>`
      height: auto;
      width: ${(props: any) => props.$width};
      margin: auto;
      transform: translateY(-36px);
      display: block;
      @media (max-width: 900px) {
        transform: translateY(-22px);
      }
      @media (max-width: 870px) {
        transform: translateY(-10px);
      }
      @media (max-width: 767px) {
        &.mobile-large {
          width: 173px;
          transform: translateY(-35px);
        }
        &.mobile-small {
          width: 146px;
          transform: translateY(-31px);
        }
      }
    `,
  },
  Scheduler: {
    Container: styled.div`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    Wrapper: styled.div`
      position: relative;
      height: 90%;
      width: 90%;
      max-height: 766px;
      max-width: 1007px;
      border-radius: 16px;
      border: none;
      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        max-height: unset;
        max-width: unset;
      }
    `,
    Scheduler: styled.iframe`
      height: 100%;
      width: 100%;
      border-radius: 16px;
      border: none;
    `,
  },
  BlogSwiper: {
    Container: styled.div`
      display: flex;
      border-radius: 20px;
      overflow: hidden;
      height: 100%;
      width: 100%;
      cursor: pointer;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    `,
    Image: styled.img`
      height: 100%;
      width: 66%;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 57%;
        width: 100%;
      }
    `,
    TextBox: styled.div`
      flex: 1;
      padding: 36px 40px;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @media (max-width: 768px) {
        padding: 28px 32px;
      }
    `,
    Headline: styled.div`
      font-size: 25px;
      font-weight: 500;
      letter-spacing: -0.35px;
      line-height: 35px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
        
      }
    `,
    Date: styled.div`
    font-size: 18px;
    letter-spacing: -0.25px;
    line-height: 22px;
    color: #5C5C5C;
  }
    `,
    PaginationButton: styled.div`
      cursor: pointer;
      border: 2px solid white;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    `
  },
  BlogCards: {
    LargeCard: styled.div`
      width: 100%;
      height: 564px;
      backgroundColor: #FFFFFFF;
      box-shadow: 0 16px 40px 0 rgba(124,141,157,0.15);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      cursor: pointer;
      transition: box-shadow .25s;
      &:hover {
        box-shadow: 0 16px 40px 0 rgba(124,141,157,0.35);
      }
      @media (max-width: 768px) {
        height: 417px
      }
    }`,
    LargeImg: styled.img`
      height: 57%;
      width: 100%;
      object-fit: cover;
      @media (max-width: 1170px) {
        height: 52%;
      }
      @media (max-width: 1000px) {
        height: 46%;
      }
      @media (max-width: 768px) {
        height: 61%;
      }
      @media (max-width: 470px) {
        height: 55%;
      }
      @media (max-width: 425px) {
        height: 50%;
      }
    `,
    LargeInfo: styled.div`
      flex: 1;
      padding: 32px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 28px 32px;
      }
    `,
    LargeHeadline: styled.div`
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.16px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 21px;
      }
    `,

    SmallCard: styled.div`
      width: 100%;
      height: 417px;
      box-shadow: 0 16px 40px 0 rgba(124, 141, 157, 0.15);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      cursor: pointer;
      transition: box-shadow .25s;
      &:hover {
        box-shadow: 0 16px 40px 0 rgba(124,141,157,0.35);
      }
    `,
    SmallImg: styled.img`
      height: 49%;
      width: 100%;
      object-fit: cover;
    `,
    SmallHeadline: styled.div`
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
    `,
    SmallInfo: styled.div`
      flex: 1;
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 768px) {
        padding: 28px 32px;
      }
    `,
    CardButton: styled.button`
      background-color: #f9f9f9f;
      cursor: pointer;
      font-family: "Gordita";
      font-size: 15px;
      letter-spacing: -0.21px;
      line-height: 20px;
      padding: 10px 43px;
      color: #3b2ec9;
      border: none;
      font-weight: 500;
      border-radius: 22px;
    `,
  },
  BlogPost: {
    HeroSection: styled.div`
      width: 100%;
      padding: 0 35px;
      max-width: 1060px;
      margin: 54px auto 64px;
      @media (max-width: 768px) {
        margin-bottom: 14px;
      }
    `,
    BackButon: styled.button`
      font-family: Gordita;
      font-weight: 500;
      display: flex;
      color: #241b6c;
      font-size: 15px;
      line-height: 20px;
      align-items: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
    `,
    Headline: styled.div`
      font-size: 45px;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: -0.63px;
      margin-bottom: 41px;
      @media (max-width: 768px) {
        font-size: 33px;
        line-height: 42px;
      }
    `,
    BlogDetails: styled.div`
      font-size: 18px;
      letter-spacing: -0.18px;
      color: #5c5c5c;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    `,
    Social: styled.img`
      height: 20px;
      width: auto;
      margin-right: 30px;
    `,
    HeroImg: styled.img`
      width: 100%;
      border-radius: 20px;
      object-fit: cover;
      margin-top: 64px;
    `,
    ContentWrapper: styled.div`
      width: 100%;
      max-width: 740px;
      padding: 0 25px;
      margin: 0 auto;
      margin-bottom: 64px;
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    BottomBar: styled.div`
      width: 100%;
      display: flex;
      border-top: 1px solid #dde6ff;
      justify-content: space-between;
      padding: 26px 0 100px;
    `,
    TextBlock: {
      Headline: styled.div`
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.42px;
        font-weight: 500;
        margin-bottom: 32px;
      `,
      Body: styled.div`
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.22px;
      `,
    },
    ImageBlock: {
      Graphic: styled.img`
        width: 100%;
        height: auto;
      `,
      Caption: styled.div`
        padding: 21px;
        font-size: 14px;
        color: #595763;
        line-height: 18px;
        letter-spacing: -0.2px;
      `,
    },
  },
  CaseStudies: {
    TextBox: styled.div`
      flex: 1;
      background-color: #1d1d1f;
      border-radius: 20px;
      padding: 64px 64px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `,
    CaseLogo: styled.img`
      width: 55%;
    `,
    CaseBody: styled.p`
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: -0.39px;
      color: #ffffff;
    `,
    Swiper: {
      Image: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
      `,
    },
  },
  Contact: {
    ScheduleBox: styled.div`
      padding: 18px 40px 0;
      display: flex;
      flex-direction: column;
      height: 437px;
      width: 457px;
      max-width: 39%;
      border-radius: 19px;
      background-image: url(${blackBG});
      background-color: rgba(0, 0, 0, 0.9);
      color: #ffffff;
      @media (max-width: 768px) {
        width: 100%;
        max-width: unset;
        height: auto;
        padding: 44px 40px;
      }
    `,
    ScheduleHeadline: styled.div`
      font-size: 45px;
      font-weight: 500;
      line-height: 56px;
      letter-spacing: -0.63px;
      margin-bottom: 24px;
      @media (max-width: 1024px) {
        font-size: 36px;
      }
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 12px;
        text-align: center;
      }
    `,
    ScheduleSub: styled.div`
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 45px;
      @media (max-width: 1024px) {
        font-size: 18px;
      }
      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 52px;
        text-align: center;
      }
    `,
    ContactFormContainer: styled.div`
      flex: 1;
      height: 437px;
      background-color: #eef5fe;
      border-radius: 19px;
      display: flex;
      flex-direction: column;
      padding: 18px 49px 0;
      @media (max-width: 768px) {
        padding: 44px;
        height: auto;
        width: 100%;
      }
    `,
    FormTitle: styled.div`
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.45px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 5px;
      @media (max-width: 1000px) {
        font-size: 24px;
      }
    `,
    FormSubtitle: styled.div`
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.25px;
      color: #000000;
      margin-bottom: 15px;
      white-space: nowrap;
      @media (max-width: 1000px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        text-align: center;
        white-space: normal;
      }
    `,
    FormError: styled.div`
      font-size: 12px;
      color: red;
      margin-top: 8px;
      position: absolute;
      bottom: -18px;
    `,
    AnswerBox: styled.div<{ $selected: boolean }>`
      cursor: pointer;
      height: 67px;
      width: 100%;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      text-align: center;
      padding: 0 10px;
      font-weight: ${(props) => (props.$selected ? "500" : "400")};
      background-color: ${(props) => (props.$selected ? "#2f50ff" : "#ffffff")};
      color: ${(props) => (props.$selected ? "#ffffff" : "#000000")};
      @media (max-width: 768px) {
        font-size: 14px;
        height: 48px;
      }
    `,
    StepCount: styled.div`
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: #5c5c5c;
    `,
    Box: styled.div`
      margin-left: 27px;
      @media (max-width: 768px) {
        margin: 16px 0 40px;
      }
    `,
    Header: styled.div`
      color: #5c5c5c;
      font-family: Gordita;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.56px;
      line-height: 18px;
      margin-bottom: 9px;
      @media (max-width: 768px) {
        text-align: center;
      }
    `,
    Body: styled.div`
      color: #000000;
      font-family: Gordita;
      font-size: 18px;
      letter-spacing: -0.25px;
      line-height: 24px;
      @media (max-width: 768px) {
        text-align: center;
      }
}`,
  },
};

export default LayoutStyles;
