import { useState } from "react";
import { useApp } from "../context/AppContext";
import { motion, useScroll } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LayoutStyles from "../App.styles";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import { useTheme, useMediaQuery, Grid } from "@mui/material";
import BlogSwiper from "../PageBlocks/BlogSwiper";
import BigBlogCard from "../Common/Cards/BigBlogCard";
import SmallBlogCard from "../Common/Cards/SmallBlogCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Resources = () => {
  const theme = useTheme();
  const { blogArray } = useApp();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const [endIndex, setEndIndex] = useState(10);

  const addBlogs = () => {
    setEndIndex((prev) => prev + 6);
  };

  const selectBlog = (blogId: string) => {
    navigate(`/resources/${blogId}`)
  }
  return (
    <>
      <TextFirstHero
        textFirst
        data={{ headline: "Elevux News" }}
      />
      <LayoutStyles.FlexboxRow
        $margin="45px 0 0 0"
        $flexDirection="column"
        $width="100%"
        style={{
          maxWidth: "1300px",
          width: "100%",
          position: "relative",
          paddingBottom: "135px",
          height: "56%",
        }}
      >
        <BlogSwiper />
        <LayoutStyles.VideoBlock.ColorBlock
          style={{ zIndex: "1" }}
          $bgColor={"#000000"}
        />
      </LayoutStyles.FlexboxRow>
      <LayoutStyles.SectionContainer $padding={isSmallScreen ? "90px 25px 0" : "118px 25px 0"}>
        <Grid spacing={5} container>
          {blogArray.slice(0, 4).map((blog) => {
            return (
              <Grid onClick={() => selectBlog(blog.id)} item xs={12} sm={6}>
                <BigBlogCard data={blog} />
              </Grid>
            );
          })}
        </Grid>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer $padding={isSmallScreen ? "32px 25px 88px" : "88px 25px 60px"}>
        <Grid spacing={4} container>
          {blogArray.slice(4, endIndex).map((blog) => {
            return (
              <Grid onClick={() => selectBlog(blog.id)} item xs={12} sm={4}>
                <SmallBlogCard data={blog} />
              </Grid>
            );
          })}
        </Grid>
      </LayoutStyles.SectionContainer>
      {endIndex < blogArray.length && (
        <LayoutStyles.FlexboxRow $justifyContent="center" $padding="0 0 95px">
          <LayoutStyles.BlogCards.CardButton onClick={addBlogs}>
            See more
            <FontAwesomeIcon
              icon={faChevronDown}
              color="#3b2ec9"
              size="xs"
              style={{ marginLeft: "6px" }}
            />
          </LayoutStyles.BlogCards.CardButton>
        </LayoutStyles.FlexboxRow>
      )}
    </>
  );
};

export default Resources;
