import React, { useState } from "react";
import LayoutStyles from "../../App.styles";
import { Link, useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContext";
import { useTheme, useMediaQuery } from "@mui/material";
import ArrowButton from "../Button/ArrowButton";

// Assets
import XurealIcon from "../../assets/images/elevux logo-horizontal-white.png";
import Instagram from "../../assets/icons/instagram.png";
import Linkedin from "../../assets/icons/linkedin.png";
import Twitter from "../../assets/icons/twitter.png";
import BBB from "../../assets/images/certifications/bbb.png";
import MBE from "../../assets/images/certifications/mbe.png";

const Footer: React.FC = () => {
  const { setCurrentPage, setShowScheduler } = useApp();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const [isLanding] = useState(
    window.location.pathname.split("/").includes('landing')
  );

  const handleNavClick = (navigateTo: string) => {
    console.log("updating current page");
    setCurrentPage(navigateTo);
  };

  return (
    <LayoutStyles.Footer.Container $isMediumScreen={isMediumScreen}>
      <LayoutStyles.Footer.InnerWrapper $isLanding={isLanding}>
     {isLanding && <LayoutStyles.Footer.TopRow style={{justifyContent: 'center'}}>
        <LayoutStyles.Footer.LandingLogo src={XurealIcon} alt="logo" />
      </LayoutStyles.Footer.TopRow>}
        {!isLanding && <LayoutStyles.Footer.TopRow>
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen
                ? "column"
                : isMediumScreen
                ? "row"
                : "column",
              width: isSmallScreen ? "auto" : isMediumScreen ? "100%" : "auto",
              justifyContent: isMediumScreen ? "space-between" : "left",
              marginBottom: isMediumScreen ? "67px" : "0",
              alignItems: isMediumScreen ? "center" : "flex-start",
            }}
          >
            <div
              style={{
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              <LayoutStyles.Footer.Logo src={XurealIcon} alt="logo" />
              <LayoutStyles.Footer.Headline>
                Get started today.
              </LayoutStyles.Footer.Headline>
            </div>
            <div style={{ height: "47px" }}>
              <ArrowButton
                clickFunction={() => navigate('/contact')}
                text="Contact us"
                bgColor={"#FFFFFF"}
                color={"#000000"}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: isSmallScreen
                ? "center"
                : isMediumScreen
                ? "flex-start"
                : "space-around",
              width: isMediumScreen ? "100%" : "auto",
            }}
          >
            <LayoutStyles.Footer.Column
              style={{
                paddingLeft: isSmallScreen ? "30px" : "0",
              }}
            >
              <LayoutStyles.Footer.Title>SOLUTIONS</LayoutStyles.Footer.Title>
              <Link to="/metaverse" onClick={() => handleNavClick("metaverse")}>
                <LayoutStyles.Footer.TextLink>
                  Metaverse
                </LayoutStyles.Footer.TextLink>
              </Link>
              <Link to="/sales" onClick={() => handleNavClick("sales")}>
                <LayoutStyles.Footer.TextLink>
                  Sales Innovation
                </LayoutStyles.Footer.TextLink>
              </Link>
              <Link to="/training" onClick={() => handleNavClick("training")}>
                <LayoutStyles.Footer.TextLink>
                  Immersive Training
                </LayoutStyles.Footer.TextLink>
              </Link>
              <Link
                to="/enterprise"
                onClick={() => handleNavClick("enterprise")}
              >
                <LayoutStyles.Footer.TextLink>
                  Enterprise
                </LayoutStyles.Footer.TextLink>
              </Link>
            </LayoutStyles.Footer.Column>
            <LayoutStyles.Footer.Column
              style={{
                paddingLeft: isMediumScreen
                  ? "45px"
                  : isSmallScreen
                  ? "30px"
                  : "71px",
              }}
            >
              <LayoutStyles.Footer.Title>
                ABOUT ELEVUX
              </LayoutStyles.Footer.Title>
              <Link to="/about" onClick={() => handleNavClick("metaverse")}>
                <LayoutStyles.Footer.TextLink>
                  Company
                </LayoutStyles.Footer.TextLink>
              </Link>
              <Link to="/terms" onClick={() => handleNavClick("terms")}>
                <LayoutStyles.Footer.TextLink>
                  Terms of Service
                </LayoutStyles.Footer.TextLink>
              </Link>
              <Link
                to="/privacy-policy"
                onClick={() => handleNavClick("privacy-policy")}
              >
                <LayoutStyles.Footer.TextLink>
                  Privacy Policy
                </LayoutStyles.Footer.TextLink>
              </Link>
            </LayoutStyles.Footer.Column>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMediumScreen ? "row" : "column",
              gap: "40px",
              alignItems: "center",
            }}
          >
            <LayoutStyles.Footer.AwardLogo
              style={{ height: "82px", width: "82px", margin: "0 auto 38px" }}
              src={MBE}
            />
            <LayoutStyles.Footer.AwardLogo src={BBB} />
          </div>
        </LayoutStyles.Footer.TopRow>}

        <LayoutStyles.Footer.BottomRow>
          <LayoutStyles.Footer.SocialBox>
            {/* <a href="" target="_blank"> */}
            {/* <LayoutStyles.Footer.SocialLInk
                src={Facebook}
                alt="Facebook link"
              /> */}
            {/* </a> */}
            <a
              href="https://www.instagram.com/elevux/"
              target="_blank"
            >
              <LayoutStyles.Footer.SocialLInk
                src={Instagram}
                alt="Instagram link"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/elevux"
              target="_blank"
            >
              <LayoutStyles.Footer.SocialLInk
                src={Linkedin}
                alt="Linkedin link"
              />
            </a>
            <a href="https://x.com/elevux" target="_blank">
              <LayoutStyles.Footer.SocialLInk
                style={{ marginRight: "0" }}
                src={Twitter}
                alt="Twitter link"
              />
            </a>
          </LayoutStyles.Footer.SocialBox>
          <LayoutStyles.Footer.LoveMessage>
            MADE WITH ❤️ IN ORLANDO
          </LayoutStyles.Footer.LoveMessage>
          <LayoutStyles.Footer.Copyright>
            © {new Date().getFullYear()} All Rights Reserved. Elevux ®
          </LayoutStyles.Footer.Copyright>
        </LayoutStyles.Footer.BottomRow>
      </LayoutStyles.Footer.InnerWrapper>
    </LayoutStyles.Footer.Container>
  );
};

export default Footer;
