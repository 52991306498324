import LayoutStyles, { colorPalette } from "../App.styles";
import TextFirstHero from "../PageBlocks/TextFirstHero";
import FeatureGrid from "../PageBlocks/FeatureGrid";
import VideoButton from "../Common/Button/VideoButton";
import ImageTextBlock from "../PageBlocks/ImageText";
import ImageCaption from "../PageBlocks/ImageCaption";
import TextText from "../PageBlocks/TextText";
import dataJson from "../data.json";
import SalesImg from "../assets/images/Homepage/immersive_sales.png";
import TrainingImg from "../assets/images/Homepage/training.png";
import AnalyticsImg from "../assets/images/Homepage/analytics.png";
import Diversity from "../assets/images/SalesEnablement/diversity.png";
import Brian from "../assets/images/brian_gill.png";
import BrianAvatar from "../assets/images/brian_avatar.png";
import { useMediaQuery } from "@mui/material";
import theme from "../theme";
import { useApp } from "../context/AppContext";
import { textTextData, parsePhrase } from "../utils/data";

const Enterprise = ({
  containerRef,
}: {
  containerRef: React.MutableRefObject<null>;
}) => {
  const { setShowVideo, setVideoURL } = useApp();

  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const under864 = useMediaQuery("(max-width:864px)");

  return (
    <>
      <LayoutStyles.SectionContainer
        $padding={isMediumScreen ? "0 0 20px 0" : "0 0 88px 0"}
        style={{ zIndex: 0 }}
      >
        <TextFirstHero
          textFirst
          data={{
            headline: parsePhrase('AI + Metaverse',"Empowering the Enterprise with AI + Metaverse"),
            subtitle:
              "With a robust content management system, analytics, and integrations, Elevux makes work and collaboration easier and straightforward.",
          }}
        />
        <LayoutStyles.SectionContainer
          $margin={"45px auto 0"}
          $width={"fit-content"}
          $padding={"0 0 64px"}
        >
          <VideoButton
            clickFunction={() => {
              setShowVideo(true);
              setVideoURL("https://player.vimeo.com/video/810937213?h=24efd4a4db");
            }}
            text="See how it works"
          />
        </LayoutStyles.SectionContainer>

        <LayoutStyles.SectionContainer $fullWidth>
          <LayoutStyles.FlexboxRow
            $flexDirection={isSmallScreen ? "column" : "row"}
            $justifyContent="center"
            $gap={isMediumScreen ? "12px" : "24px"}
          >
            <ImageCaption
              path="/metaverse"
              bgColor="#ffffff"
              image={SalesImg}
              caption="AI + Metaverse"
              bullets={[
                "Sell more with the Metaverse",
                "Sales Innovation",
                "Augmented Reality",
                "3D Product Visualizations",
                "Sales Content Management",
                "Virtual Sales Room",
                "E-Commerce Integrations",
              ]}
              buttonText="Learn more"
            />
            <ImageCaption
              path="/sales"
              bgColor="#ffffff"
              caption="Sales Innovation"
              image={AnalyticsImg}
              bullets={[
                "Organize your Sales Content",
                "Sales Content Management",
                "Content Distribution",
                "Cloud Media Library",
                "Sales Content Analytics",
                "User Management",
              ]}
              buttonText="Learn more"
            />
            <ImageCaption
              path="/training"
              bgColor="#ffffff"
              image={TrainingImg}
              caption="Immersive Training"
              bullets={[
                "Train High-performing Teams",
                "Employee Onboarding",
                "Gamification in Learning",
                "Engagement & Retention",
                "Product Visualizations",
                "Sales Training",
                "Content Management System",
                "Peer-to-peer Learning",
                "Launch Products & Processes",
              ]}
              buttonText="Learn more"
            />
            
          </LayoutStyles.FlexboxRow>
        </LayoutStyles.SectionContainer>
        {!isSmallScreen && <div style={{
              height: isMediumScreen ? '590px' : '608.5px',
              backgroundColor: '#000000',
              width: '300vw',
              position: 'absolute',
              bottom: '0',
              right: '-50%',
              zIndex: '-1'
            }} />}
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer $backgroundColor={"#EEF5FE"} $padding="0" $minHeight={"774px"}>
        <TextText
          height={isMediumScreen ? "auto" : "618px"}
          data1={dataJson.engagement.security}
          data2={dataJson.engagement.integrations}
          padding={isMediumScreen ? "55px 0" : "165px 0 0"}
          hideButton
          lineColor="#FDEFCA"
          alignItems="flex-start"
          justifyContent={isMediumScreen ? "center" : "space-between"}
          iconRow1={textTextData.homepage.security.iconRow}
          iconRow2={textTextData.homepage.integrations.iconRow}
        ></TextText>
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor={theme.palette.neutral.black}
        $padding={isMediumScreen ? "80px 0 50px 0" : "160px 0 100px 0"}
      >
        <FeatureGrid data={dataJson.enterprise.features} />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $backgroundColor={"#EEF5FE"}
        $padding={
          isSmallScreen ? "60px 0 100px" : "150px 0"
        }
      >
        <ImageTextBlock
          data={dataJson.metaverse.diversity}
          padding={!isMediumScreen ? "0 75px" : "0"}
          alignItems={isMediumScreen ? "center" : "flex-end"}
          imageSize={[isMediumScreen ? "auto" : 751, "100%"]}
          image={Diversity}
          hideButton
          reverse
          color={"#F9F9F9"}
          height={"100%"}
          altColor={false}
        />
      </LayoutStyles.SectionContainer>
      <LayoutStyles.SectionContainer
        $padding={isMediumScreen ? "100px 0" : "189px 0 203px"}
      >
         <ImageTextBlock
          lottieAnimation={[Brian, !isSmallScreen && BrianAvatar]}
          testimonial
          data={dataJson.homepage.testimonials}
          alignItems="center"
          justifyContent="space-between"
          padding={!isMediumScreen ? "0 75px" : "unset"}
          avatarSize={["auto", "85%"]}
          avatarPosition={
            isSmallScreen
              ? ["25%", "auto", "auto", "60%"]
              : ["25%", "auto", "auto", "70%"]
          }
          imageSize={
            isSmallScreen
              ? ['90%', "auto"]
              : [433, "auto"]
          }
          gap={isSmallScreen ? "40px" : "80px"}
          hideTag
          removeTxtPadding
          quote
          isBackgroundImg={[true, false]}
          hideButton
          altColor={true}
        />
      </LayoutStyles.SectionContainer>
    </>
  );
};

export default Enterprise;
